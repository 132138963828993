const from_distances = [
	{
		label: "0",
		value: 0
	},
	{
		label: "500 feet",
		value: 500
	},
	{
		label: "1000 feet",
		value: 1000
	},
	{
		label: "1500 feet",
		value: 1500
	},
	{
		label: "2000 feet",
		value: 2000
	},
	{
		label: "0.5 mile (2640 feet)",
		value: 2640
	},
	{
		label: "1 mile (5280 feet)",
		value: 5280
	}
];

const to_distances = [
	{
		label: "0",
		value: 0
	},
	{
		label: "0.5 mile (2640 feet)",
		value: 2640
	},
	{
		label: "1 mile (5280 feet)",
		value: 5280
	},
	{
		label: "2 miles (10560 feet)",
		value: 10560
	},
	{
		label: "4 miles (21120 feet)",
		value: 21120
	},
	{
		label: "6 miles (31680 feet)",
		value: 31680
	},
	{
		label: "8 miles (42240 feet)",
		value: 42240
	},
	{
		label: "10 miles (52800 feet)",
		value: 52800
	},
	{
		label: "15 miles (79200 feet)",
		value: 79200
	},
	{
		label: "20 miles",
		value: 105600
	},
	{
		label: "25 miles",
		value: 132000
	},
	{
		label: "30 miles",
		value: 158400
	},
	{
		label: "40 miles",
		value: 211200
	},
	{
		label: "50 miles",
		value: 264000
	},
	{
		label: "60 miles",
		value: 316800
	},
	{
		label: "70 miles",
		value: 369600
	},
	{
		label: "80 miles",
		value: 422400
	},
	{
		label: "90 miles",
		value: 475200
	},
	{
		label: "100 miles",
		value: 528000
	}
];

export { from_distances, to_distances };
