import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import routes from "../../routes";
import UserService from "../../services/UserService";
import Header from "../Header/Header";
import { GROUP_PERMISSIONS } from "../../constants/GroupPermission";
import Footer from "../Footer/Footer";
import ForceReset from "../ForceReset/ForceReset";

var ps;

class Main extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			backgroundColor: "black",
			activeColor: "info"
		};
		this.mainPanel = React.createRef();
	}
	componentDidMount() {
		if (navigator.platform.indexOf("Win") > -1) {
			ps = new PerfectScrollbar(this.mainPanel.current);
			document.body.classList.toggle("perfect-scrollbar-on");
		}
	}
	componentWillUnmount() {
		if (navigator.platform.indexOf("Win") > -1) {
			ps.destroy();
			document.body.classList.toggle("perfect-scrollbar-on");
		}
	}
	componentDidUpdate(e) {
		if (e.history.action === "PUSH") {
			this.mainPanel.current.scrollTop = 0;
			document.scrollingElement.scrollTop = 0;
		}
	}
	handleActiveClick = color => {
		this.setState({ activeColor: color });
	};
	handleBgClick = color => {
		this.setState({ backgroundColor: color });
	};
	render() {
		return (
			<div className="wrapper">
				<Sidebar
					{...this.props}
					routes={routes}
					bgColor={this.state.backgroundColor}
					activeColor={this.state.activeColor}
				/>
				<div className="main-panel" ref={this.mainPanel}>
					<Header {...this.props} />
					<Switch>
						{routes.map((prop, key) => {
							if (prop.admin) {
								if (
									UserService.get().GroupPermission.id ===
									GROUP_PERMISSIONS.superAdmin.id
								) {
									return (
										<Route
											path={prop.layout + prop.path}
											component={prop.component}
											key={key}
										/>
									);
								}
								return null;
							}
							return (
								<Route
									path={prop.layout + prop.path}
									component={prop.component}
									key={key}
								/>
							);
						})}
						<Route path="/v1/force-reset" exact={true} component={ForceReset} />
					</Switch>
					<Footer fluid />
				</div>
			</div>
		);
	}
}

export default Main;
