const StringService = {
	/**
	 * This function takes in querystring returned by react-router's this.props.location.search as input
	 * @param {String} queryString example input: ?key1=value1&key2=value2
	 * @returns {Object}
	 * The function will return an object in following format {key1: value1, key2: value2}
	 */
	parseQueryString(queryString) {
		const retVal = {};
		const queryStringArr = queryString.slice(1, queryString.length).split("&");
		queryStringArr.forEach(aQueryString => {
			const keyValueArr = aQueryString.split("=");
			retVal[keyValueArr[0]] = keyValueArr[1] || "";
		});
		return retVal;
	}
};
export default StringService;
