import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import { Redirect } from "react-router-dom";
import config from "../../config/config";
import Axios from "axios";
import UserService from "../../services/UserService";
import { Card, CardBody, Row, Col } from "reactstrap";

const CurrentLocationPin = () => (
	<img
		style={{ position: "absolute", transform: "translate(-50%, -100%)" }}
		src={`${config.common.CDN_URL}/img/marker.png`}
		width="50px"
		alt="my location pin"
	/>
);

const PromoPin = ({ logoUrl, promoUrl }) => {
	return (
		<div
			style={{
				backgroundColor: "transparent",
				position: "absolute",
				transform: "translate(-50%, -100%)"
			}}
		>
			{logoUrl ? (
				<div
					style={{
						backgroundColor: "#222222",
						padding: 10
					}}
				>
					<div
						style={{
							backgroundColor: "#fff"
						}}
					>
						<img
							src={logoUrl}
							style={{
								width: 70,
								height: 70
							}}
							alt="logo"
						/>
					</div>
				</div>
			) : (
				<div
					style={{
						backgroundColor: "#222222",
						padding: 10
					}}
				>
					<div
						style={{
							backgroundColor: "#fff"
						}}
					>
						<img
							src={promoUrl}
							style={{
								width: 70,
								height: 70,
								resizeMode: "cover"
							}}
							alt="promo"
						/>
					</div>
				</div>
			)}
			<div
				style={{
					width: 0,
					height: 0,
					backgroundColor: "transparent",
					borderStyle: "solid",
					borderTopWidth: 26,
					borderRightWidth: 13,
					borderBottomWidth: 0,
					borderLeftWidth: 13,
					marginLeft: 32.5,
					marginTop: -10,
					borderBottomColor: "transparent",
					borderRightColor: "transparent",
					borderTopColor: "#222222",
					borderLeftColor: "transparent"
				}}
			/>
		</div>
	);
};
class MapView extends Component {
	state = {
		center: {
			lat: 41.8781,
			lng: 87.6298
		},
		promos: []
	};

	static defaultProps = {
		center: {
			lat: 41.8781,
			lng: 87.6298
		},
		zoom: 14
	};
	getGeoLocation() {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition((position) =>
				this.displayLocationInfo(position)
			);
		} else {
			// no can do
		}
	}

	async fetchPromos() {
		try {
			const promotions = await Axios.get(
				`${config.PLATFORM_API}/promotions/fetch_client_promos`,
				{
					headers: {
						Authorization: UserService.getAuthToken()
					}
				}
			);
			this.setState({ promos: promotions.data });
		} catch (error) {
			if (
				error.response &&
				error.response.status &&
				error.response.status === 401
			) {
				UserService.clear();
				this.setState({ unauthorized: true });
			}
			console.log(error);
		}
	}

	displayLocationInfo(position) {
		const lng = position.coords.longitude;
		const lat = position.coords.latitude;

		this.setState({ center: { lat, lng } });
	}
	componentDidMount() {
		this.getGeoLocation();
		this.fetchPromos();
	}
	render() {
		if (!UserService.isSubscribed()) {
			return <Redirect to="/admin/purchase" />;
		}
		const { promos } = this.state;
		return (
			<div className="content">
				<Row>
					<Col md="12">
						<Card>
							<CardBody>
								<div style={{ height: "78vh", width: "100%" }}>
									<GoogleMapReact
										bootstrapURLKeys={{
											key: "AIzaSyDSVG3cLqIYjnciLILKnJaxb_R1DgQ_qf8"
										}}
										defaultCenter={this.props.center}
										center={this.state.center}
										defaultZoom={this.props.zoom}
										yesIWantToUseGoogleMapApiInternals
									>
										<CurrentLocationPin
											lat={this.state.center.lat}
											lng={this.state.center.lng}
										/>
										{promos &&
											promos.length &&
											promos.map((aPromo, key) => {
												return (
													<PromoPin
														key={key}
														lat={aPromo.lat}
														lng={aPromo.lng}
														logoUrl={aPromo.logo_url}
														promoUrl={aPromo.url}
													/>
												);
											})}
									</GoogleMapReact>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</div>
		);
	}
}

export default MapView;
