import React, { Component } from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	TextField,
	DialogTitle,
	colors,
	MenuItem,
	Fab,
} from "@material-ui/core";
import { Card, CardBody, Row, Col, Spinner } from "reactstrap";
import {
	Add as AddIcon,
	AttachFile as AttachFileIcon,
	CheckCircle,
} from "@material-ui/icons";
import Axios from "axios";
import config from "../../config/config";
import UserService from "../../services/UserService";
import SweetAlert from "react-bootstrap-sweetalert";
import { Redirect } from "react-router-dom";
import { DropzoneArea } from "material-ui-dropzone";
import * as Icons from "react-feather";
import ReactSwitch from "react-switch";
import ReactGA from "react-ga4";
import ExcelJS from "exceljs";
import toast, { Toaster, CheckmarkIcon } from "react-hot-toast";
import "./promotions.css";

class Promotions extends Component {
	state = {
		promotions: [],
		showUpdateModal: false,
		toUpdateUser: undefined,
		hasError: false,
		errorText: "",
		showInfo: false,
		infoText: "",
		id: "",
		name: "",
		category: "",
		image: "",
		copy: "",
		keywords: "",
		logoImage: "",
		address: "",
		address2: "",
		zip: "",
		city: "",
		state: "",
		country: "United States",
		status: "",
		qrRedirectUrl: "",
		showCreateModal: false,
		redirect: false,
		loading: true,
		fetchInactive: false,
	};
	componentDidMount() {
		this.fetchPromotions();
	}
	resetState(cb) {
		this.setState(
			{
				id: "",
				name: "",
				category: "",
				image: "",
				copy: "",
				keywords: "",
				logoImage: "",
				address: "",
				address2: "",
				zip: "",
				city: "",
				state: "",
				country: "United States",
				status: "",
				qrRedirectUrl: "",
			},
			() => cb()
		);
	}
	handlePromotionPictureOnChange(files) {
		if (files.length === 0) {
			return;
		}
		this.setState({ image: files[0] });
	}
	handleLogoPictureOnChange(files) {
		if (files.length === 0) {
			return;
		}
		this.setState({ logoImage: files[0] });
	}
	async fetchPromotions() {
		try {
			// this.setState({ loading: true });
			const { fetchInactive } = this.state;
			const promotions = await Axios.get(
				`${config.PLATFORM_API}/promotions/fetch_client_promos${
					fetchInactive ? "?inactive_only=true" : ""
				}`,
				{
					headers: {
						Authorization: UserService.getAuthToken(),
					},
				}
			);
			this.setState({ promotions: promotions.data });
		} catch (error) {
			if (
				error.response &&
				error.response.status &&
				error.response.status === 401
			) {
				UserService.clear();
				this.setState({ unauthorized: true });
			}
			console.log(error);
		}
		this.setState({ loading: false });
	}
	async createPromo() {
		const {
			name,
			category,
			image,
			logoImage,
			qrRedirectUrl,
			address,
			address2,
			copy,
			keywords,
			zip,
			city,
			state,
			country,
		} = this.state;
		if (!image) {
			this.setState({ hasError: true, errorText: "Photo is required" });
			return;
		}
		if (!city) {
			this.setState({ hasError: true, errorText: "City is required" });
			return;
		}
		if (!country) {
			this.setState({ hasError: true, errorText: "Country is required" });
			return;
		}
		try {
			const formData = new FormData();
			//maintain the image sequence
			formData.append("file", image);
			formData.append("file", logoImage);
			formData.append("qr_redirect_url", qrRedirectUrl);
			formData.append("filename", image.name);
			formData.append("name", name);
			formData.append("category", category);
			formData.append("keywords", keywords);
			formData.append("copy", copy);
			formData.append("address", address);
			formData.append("address2", address2);
			formData.append("zip", zip);
			formData.append("city", city);
			formData.append("state", state);
			formData.append("country", country);

			await Axios.post(
				`${config.PLATFORM_API}/promotions/create`,
				formData,
				{
					headers: { Authorization: UserService.getAuthToken() },
				}
			);

			ReactGA.event({
				category: "button_click",
				action: "promotion_create",
			});
			this.setState({
				showInfo: true,
				infoText: "Promotion Created",
			});
			await this.fetchPromotions();
		} catch (error) {
			if (
				error.response &&
				error.response.status &&
				error.response.status === 401
			) {
				UserService.clear();
				this.setState({ unauthorized: true });
			}
			console.log(error);
			this.setState({
				hasError: true,
				errorText: "Error Saving Promotions!",
			});
		}
	}
	async updatePromo() {
		try {
			const {
				id,
				name,
				category,
				image,
				logoImage,
				qrRedirectUrl,
				address,
				address2,
				keywords,
				copy,
				zip,
				city,
				state,
				country,
				status,
			} = this.state;
			if (!city) {
				this.setState({
					hasError: true,
					errorText: "City is required",
				});
				return;
			}
			if (!country) {
				this.setState({
					hasError: true,
					errorText: "Country is required",
				});
				return;
			}
			const formData = new FormData();
			formData.append("file", image);
			formData.append("logoImage", logoImage);
			formData.append("qr_redirect_url", qrRedirectUrl);
			formData.append("filename", "filename");
			formData.append("name", name);
			formData.append("category", category);
			formData.append("address", address);
			formData.append("keywords", keywords);
			formData.append("copy", copy);
			formData.append("address2", address2);
			formData.append("zip", zip);
			formData.append("city", city);
			formData.append("state", state);
			formData.append("country", country);
			formData.append("status", status);

			await Axios.post(
				`${config.PLATFORM_API}/promotions/${id}/update`,
				formData,
				{
					headers: {
						Authorization: UserService.getAuthToken(),
					},
				}
			);
			if (status !== "deleted") {
				ReactGA.event({
					category: "button_click",
					action: "promotion_update",
				});
			} else {
				ReactGA.event({
					category: "button_click",
					action: "promotion_delete",
				});
			}

			this.setState({
				showInfo: true,
				infoText: "Promotion Updated",
			});
			await this.fetchPromotions();
		} catch (error) {
			if (
				error.response &&
				error.response.status &&
				error.response.status === 401
			) {
				UserService.clear();
				this.setState({ unauthorized: true });
			}
			console.log(error);
			this.setState({
				hasError: true,
				errorText: "Error Saving Promotion!",
			});
		}
	}
	getFile() {
		document.getElementById("excel-import").click();
	}
	async handleFiles(event) {
		const fileList = event.target.files;
		const wb = new ExcelJS.Workbook();
		const reader = new FileReader();

		reader.readAsArrayBuffer(fileList[0]);
		reader.onload = () => {
			const buffer = reader.result;
			wb.xlsx.load(buffer).then((workbook) => {
				toast.promise(
					this.handleWorkbook(workbook),
					{
						loading: "Uploading Promotions",
						success: "Promotions uploaded successfully!",
						error: "Error uploading Promotions",
					},
					{
						position: "top-right",
						loading: {
							style: {
								background: "rgb(76, 175, 80)",
								color: "white",
								fontSize: 20,
								height: "200%",
							},
							iconTheme: {
								primary: "white",
							},
							icon: <Spinner />,
						},
						success: {
							style: {
								background: "rgb(76, 175, 80)",
								color: "white",
								fontSize: 18,
							},
							iconTheme: {
								primary: "white",
								secondary: "rgb(76, 175, 80)",
							},
							icon: <CheckCircle />,
							duration: 4000,
						},
					}
				);
			});
		};
	}

	async handleWorkbook(workbook) {
		try {
			const worksheet = workbook.worksheets[0];
			const rows = [];

			worksheet.eachRow((row) => {
				rows.push(row.values);
				return;
			});

			const images = [];
			const promises = [];
			const worksheetImages = worksheet.getImages().entries();
			for (const [index, image] of worksheetImages) {
				promises.push(
					this.processImage(image, workbook)
						.then((url) => images.push({ url, index }))
						.catch((e) => {
							throw new Error(e);
						})
				);
			}
			await Promise.all(promises);
			const sortedImages = images
				.sort((a, b) => a.index - b.index)
				.map((obj) => obj.url);
			const finalImages = [];
			for (let i = 0; i < sortedImages.length; i += 2) {
				finalImages.push(sortedImages.slice(i, i + 2));
			}

			const eachPromotionKeys = rows[0].slice(1);

			const promotionsWithoutImages = rows.slice(1).map((row) => {
				const eachPromo = {};
				eachPromotionKeys.forEach((key, i) => {
					eachPromo[key] = row[i + 1];
				});
				return eachPromo;
			});

			const promotions = promotionsWithoutImages.map((promo, index) => {
				promo["url"] = finalImages[index][0];
				promo["logo_url"] = finalImages[index][1];
				return promo;
			});
			await Axios.post(
				`${config.PLATFORM_API}/promotions/bulk_create`,
				{ promotions },
				{
					headers: {
						Authorization: UserService.getAuthToken(),
					},
				}
			);
			this.fetchPromotions();
		} catch (e) {
			console.log(e);
		}
	}
	async processImage(image, workbook) {
		const img = workbook.model.media.find((m) => m.index === image.imageId);
		const blob = new Blob([img.buffer], {
			type: "application/octet-stream",
		});
		const fileName = "temp.png";
		const file = new File([blob], fileName);
		const formData = new FormData();
		formData.append("file", file);
		const x = await Axios.post(
			`${config.PLATFORM_API}/promotions/upload_image`,
			formData,
			{
				headers: {
					Authorization: UserService.getAuthToken(),
				},
			}
		);
		return x.data.url;
	}
	render() {
		const {
			promotions,
			showUpdateModal,
			name,
			category,
			address,
			address2,
			zip,
			city,
			state,
			country,
			keywords,
			copy,
			status,
			hasError,
			errorText,
			showInfo,
			infoText,
			showCreateModal,
			unauthorized,
			loading,
			qrRedirectUrl,
			fetchInactive,
		} = this.state;
		if (unauthorized) {
			return <Redirect to="login" />;
		}
		if (!UserService.isSubscribed()) {
			return <Redirect to="/admin/purchase" />;
		}
		if (loading) {
			return (
				<div className="content spinner-center">
					<Spinner
						style={{ width: "14rem", height: "14rem" }}
						type="grow"
						color="success"
					/>
				</div>
			);
		}

		return (
			<div className="content">
				<div
					style={{
						position: "fixed",
						bottom: 17,
						right: 17,
						zIndex: 10000000,
						display: "flex",
						justifyContent: "space-between",
						width: "6.25%",
					}}
				>
					<Fab
						style={{
							backgroundColor: colors.green[500],
						}}
						aria-label="attach-file"
						onClick={() => this.getFile()}
					>
						<AttachFileIcon style={{ color: "white" }} />
						<input
							onChange={(e) => this.handleFiles(e)}
							onClick={(e) => (e.target.value = null)}
							type="file"
							id="excel-import"
							hidden
						/>
					</Fab>
					<Fab
						style={{
							backgroundColor: colors.green[500],
						}}
						aria-label="add"
						onClick={() =>
							this.resetState(() =>
								this.setState({
									showCreateModal: true,
									showUpdateModal: false,
								})
							)
						}
					>
						<AddIcon style={{ color: "white" }} />
					</Fab>
				</div>

				<div>
					<Row>
						<Col md="12">
							<Card>
								<CardBody>
									<div
										style={{
											display: "flex",
											justifyContent: "space-around",
											alignItems: "center",
											width: 405,
											fontSize: 16,
											marginBottom: 25,
										}}
									>
										<div>Active Promotions</div>
										<ReactSwitch
											onChange={(value) => {
												console.log(value);
												this.setState(
													{
														fetchInactive: value,
														loading: true,
													},
													() => this.fetchPromotions()
												);
											}}
											checked={fetchInactive}
										/>
										<div>Inactive Promotions</div>
									</div>
									<Table stickyHeader>
										<TableHead>
											<TableRow>
												<TableCell>Name</TableCell>
												{UserService.get()
													.GroupPermission.id ===
													1 && (
													<TableCell>
														User Id
													</TableCell>
												)}
												<TableCell>Category</TableCell>
												<TableCell
													style={{ width: "140px" }}
												>
													QR
												</TableCell>
												<TableCell>Image</TableCell>
												<TableCell>Logo</TableCell>
												<TableCell>
													Remaining Impressions
												</TableCell>
												<TableCell>City</TableCell>
												<TableCell>Country</TableCell>
												<TableCell>Actions</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{promotions.map(
												(aPromotion, key) => (
													<TableRow
														key={aPromotion.id}
													>
														<TableCell>
															{key + 1}.{" "}
															{aPromotion.name}
														</TableCell>
														{UserService.get()
															.GroupPermission
															.id === 1 && (
															<TableCell>
																{
																	aPromotion.user_id
																}
															</TableCell>
														)}
														<TableCell>
															{
																aPromotion.category
															}
														</TableCell>
														<TableCell>
															{aPromotion.qr_url ? (
																<img
																	// className="ahia-promotions-promotion-url"
																	src={
																		aPromotion.qr_url
																	}
																	alt="promotions"
																	width="80px"
																	height="80px"
																/>
															) : (
																"No QR"
															)}
														</TableCell>
														<TableCell>
															<img
																// className="ahia-promotions-promotion-url"
																src={
																	aPromotion.url
																}
																alt="promotions"
																height="40px"
															/>
														</TableCell>
														<TableCell>
															{aPromotion.logo_url ? (
																<img
																	// className="ahia-promotions-promotion-url"
																	src={
																		aPromotion.logo_url
																	}
																	alt="promotions"
																	height="40px"
																/>
															) : (
																"No Logo"
															)}
														</TableCell>
														<TableCell>
															{
																aPromotion.remaining_impressions
															}
														</TableCell>
														<TableCell>
															{aPromotion.city}
														</TableCell>
														<TableCell>
															{aPromotion.country}
														</TableCell>
														<TableCell>
															<Icons.Edit
																size="18"
																onClick={() =>
																	this.setState(
																		{
																			id: aPromotion.id,
																			name: aPromotion.name,
																			category:
																				aPromotion.category,
																			address:
																				aPromotion.address_1,
																			address2:
																				aPromotion.address_2,
																			copy: aPromotion.copy,
																			keywords:
																				aPromotion.keywords,
																			zip: aPromotion.zip_postal,
																			city: aPromotion.city,
																			state: aPromotion.state_province,
																			country:
																				aPromotion.country,
																			status: aPromotion.status,
																			qrRedirectUrl:
																				aPromotion.qrRedirectUrl,
																		},
																		() =>
																			this.setState(
																				{
																					showUpdateModal: true,
																					showCreateModal: false,
																				}
																			)
																	)
																}
															/>{" "}
															<Icons.Trash2
																size="18"
																onClick={() =>
																	this.setState(
																		{
																			id: aPromotion.id,
																			name: aPromotion.name,
																			category:
																				aPromotion.category,
																			address:
																				aPromotion.address_1,
																			address2:
																				aPromotion.address_2,
																			zip: aPromotion.zip_postal,
																			city: aPromotion.city,
																			state: aPromotion.state,
																			country:
																				aPromotion.country,
																			status: "deleted",
																		},
																		this
																			.updatePromo
																	)
																}
															/>
														</TableCell>
													</TableRow>
												)
											)}
										</TableBody>
									</Table>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</div>

				{(showUpdateModal || showCreateModal) && (
					<Dialog
						open={showUpdateModal || showCreateModal}
						keepMounted
						onClose={() =>
							this.setState({
								showUpdateModal: false,
								showCreateModal: false,
							})
						}
						aria-labelledby="alert-dialog-slide-title"
						aria-describedby="alert-dialog-slide-description"
					>
						<div style={{ margin: "30px" }}>
							<DialogTitle id="alert-dialog-slide-title">
								{showCreateModal ? "Create" : "Update"}{" "}
								Promotion
							</DialogTitle>
							<DialogContent>
								<form>
									<TextField
										autoFocus
										id="name"
										margin="dense"
										label="Name"
										type="text"
										fullWidth
										value={name || ""}
										onChange={(event) =>
											this.setState({
												name: event.target.value,
											})
										}
									/>
									<TextField
										margin="dense"
										label="Category"
										type="text"
										fullWidth
										value={category || ""}
										onChange={(event) =>
											this.setState({
												category: event.target.value,
											})
										}
									/>
									<TextField
										margin="dense"
										label="Copy"
										type="text"
										fullWidth
										value={copy || ""}
										onChange={(event) =>
											this.setState({
												copy: event.target.value,
											})
										}
									/>
									<TextField
										margin="dense"
										label="Keywords(CSV)"
										type="text"
										fullWidth
										value={keywords || ""}
										onChange={(event) =>
											this.setState({
												keywords: event.target.value,
											})
										}
									/>
									<DropzoneArea
										filesLimit={1}
										onChange={(files) =>
											this.handlePromotionPictureOnChange(
												files
											)
										}
										dropzoneText="Drag and drop a Promotion image or click here"
									/>
									<DropzoneArea
										filesLimit={1}
										onChange={(files) =>
											this.handleLogoPictureOnChange(
												files
											)
										}
										dropzoneText="Drag and drop a Logo image or click here"
									/>
									<TextField
										margin="dense"
										label="QR Redirect URL(Eg: Company/Promotion Website)"
										type="text"
										autoComplete="off"
										fullWidth
										value={qrRedirectUrl || ""}
										onChange={(event) =>
											this.setState({
												qrRedirectUrl:
													event.target.value,
											})
										}
										required
									/>
									<TextField
										margin="dense"
										label="Address"
										type="text"
										autoComplete="off"
										fullWidth
										value={address || ""}
										onChange={(event) =>
											this.setState({
												address: event.target.value,
											})
										}
									/>
									<TextField
										margin="dense"
										label="Address 2"
										type="text"
										fullWidth
										value={address2 || ""}
										onChange={(event) =>
											this.setState({
												address2: event.target.value,
											})
										}
									/>
									<TextField
										margin="dense"
										label="City"
										type="text"
										fullWidth
										value={city || ""}
										onChange={(event) =>
											this.setState({
												city: event.target.value,
											})
										}
									/>
									<TextField
										margin="dense"
										label="State"
										type="text"
										fullWidth
										value={state || ""}
										onChange={(event) =>
											this.setState({
												state: event.target.value,
											})
										}
									/>
									<TextField
										margin="dense"
										label="Postal Code"
										type="text"
										fullWidth
										value={zip || ""}
										onChange={(event) =>
											this.setState({
												zip: event.target.value,
											})
										}
									/>
									<TextField
										margin="dense"
										label="Country"
										required
										type="text"
										fullWidth
										value={country || ""}
										onChange={(event) =>
											this.setState({
												country: event.target.value,
											})
										}
									/>
									{showUpdateModal && (
										<TextField
											value={status || "active"}
											fullWidth
											select
											label="Status"
											onChange={(event) =>
												this.setState({
													status: event.target.value,
												})
											}
										>
											<MenuItem value={"active"}>
												Active
											</MenuItem>
											<MenuItem value={"inactive"}>
												Inactive
											</MenuItem>
											<MenuItem value={"deleted"}>
												Deleted
											</MenuItem>
										</TextField>
									)}
								</form>
							</DialogContent>
							<DialogActions>
								{showUpdateModal && (
									<Button
										onClick={() => this.updatePromo()}
										style={{
											backgroundColor: colors.green[500],
											color: "#fff",
										}}
										variant="contained"
										type="submit"
									>
										Save
									</Button>
								)}
								{showCreateModal && (
									<Button
										onClick={() => this.createPromo()}
										style={{
											backgroundColor: colors.green[500],
											color: "#fff",
										}}
										variant="contained"
										type="submit"
									>
										Create
									</Button>
								)}
								<Button
									onClick={() =>
										this.setState({
											showUpdateModal: false,
											showCreateModal: false,
										})
									}
									color="secondary"
									variant="contained"
								>
									Cancel
								</Button>
							</DialogActions>
						</div>
						<SweetAlert
							title="Error"
							show={hasError}
							error
							confirmBtnText="Ok"
							confirmBtnCssClass="MuiButtonBase-root MuiButton-root ahia-login-button MuiButton-contained MuiButton-containedSecondary"
							onConfirm={() =>
								this.setState({
									hasError: false,
									errorText: "",
								})
							}
						>
							{errorText}
						</SweetAlert>
						<SweetAlert
							title="Success"
							show={showInfo}
							success
							confirmBtnText="Ok"
							confirmBtnCssClass="MuiButtonBase-root MuiButton-root ahia-login-button MuiButton-contained MuiButton-containedPrimary"
							onConfirm={() =>
								this.setState({
									showInfo: false,
									infoText: "",
									showCreateModal: false,
									showUpdateModal: false,
								})
							}
						>
							{infoText}
						</SweetAlert>
					</Dialog>
				)}
				<Toaster />
			</div>
		);
	}
}

export default Promotions;
