import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import "../components.css";
import "./dashboard.css";
import Axios from "axios";
import config from "../../config/config";
import UserService from "../../services/UserService";
import {
	Card,
	CardBody,
	CardFooter,
	CardTitle,
	Row,
	Col,
	CardHeader,
	Spinner
} from "reactstrap";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import { Line } from "react-chartjs-2";

import { dashboard24HoursPerformanceChart } from "../../variables/charts.jsx";
import moment from "moment";
import { formatDate, parseDate } from "react-day-picker/moment";

class Dashboard extends Component {
	state = {
		totalLikes: 0,
		totalImpressions: 0,
		totalCompletions: 0,
		totalQrScans: 0,
		totalPromotions: 0,
		hasError: false,
		errorText: "",
		unauthorized: false,
		loading: false,
		timeSinceReload: 0,
		reloadTime: moment(),
		impressions: {},
		likes: {},
		completions: {},
		impressionsFrom: moment().subtract("7", "days").toDate(),
		impressionsTo: moment().toDate(),
		likesTo: moment().toDate(),
		likesFrom: moment().subtract("7", "days").toDate(),
		completionsTo: moment().toDate(),
		completionsFrom: moment().subtract("7", "days").toDate(),
		completionsGraphLoading: false,
		likesGraphLoading: false,
		impressionsGraphLoading: false
	};
	async showFromMonth(type) {
		if (!this.state[type + "From"]) {
			return;
		}
		if (
			moment(this.state[type + "To"]).diff(
				moment(this.state[type + "From"]),
				"months"
			) < 2
		) {
			this[type + "To"].getDayPicker().showMonth(this.state[type + "From"]);
		}
	}
	handleFromChange(from, type) {
		console.log(from);
		// Change the from date and focus the "to" input field
		this.setState({ [type + "From"]: from });
	}

	handleToChange(to, type) {
		this.setState({ [type + "To"]: to }, () => this.showFromMonth(type));
	}
	async fetchDashboardData() {
		try {
			this.setState({ loading: true });
			const dashboardData = await Axios.get(
				`${config.PLATFORM_API}/stats/dashboard`,
				{
					headers: {
						Authorization: UserService.getAuthToken()
					}
				}
			);
			this.setState({
				totalPromotions: dashboardData.data.total_promotions,
				totalLikes: dashboardData.data.total_likes,
				totalImpressions: dashboardData.data.total_impressions,
				totalCompletions: dashboardData.data.total_completions,
				totalQrScans: dashboardData.data.total_qr_scans
			});
		} catch (error) {
			if (
				error.response &&
				error.response.status &&
				error.response.status === 401
			) {
				UserService.clear();
				this.setState({ unauthorized: true });
			}
			console.log(error);
			this.setState({ hasError: true, errorText: "Something went wrong!" });
		}
		this.setState({ loading: false });
	}

	async fetchImpressionsData() {
		this.setState({ impressionsGraphLoading: true });
		let { impressionsFrom, impressionsTo } = this.state;
		impressionsFrom = moment(impressionsFrom).format("YYYY-MM-DD");
		impressionsTo = moment(impressionsTo).format("YYYY-MM-DD");
		try {
			const imprsssionsData = await Axios.get(
				`${config.PLATFORM_API}/stats/impressions?from=${impressionsFrom}&to=${impressionsTo}`,
				{
					headers: {
						Authorization: UserService.getAuthToken()
					}
				}
			);
			this.setState({ impressions: imprsssionsData.data });
		} catch (error) {
			console.log(error);
		}
		this.setState({ impressionsGraphLoading: false });
	}
	async fetchCompletionsData() {
		this.setState({ completionsGraphLoading: true });

		let { completionsFrom, completionsTo } = this.state;
		completionsFrom = moment(completionsFrom).format("YYYY-MM-DD");
		completionsTo = moment(completionsTo).format("YYYY-MM-DD");

		try {
			const completionsData = await Axios.get(
				`${config.PLATFORM_API}/stats/completions?from=${completionsFrom}&to=${completionsTo}`,
				{
					headers: {
						Authorization: UserService.getAuthToken()
					}
				}
			);
			this.setState({ completions: completionsData.data });
		} catch (error) {
			console.log(error);
		}
		this.setState({ completionsGraphLoading: false });
	}
	async fetchLikesData() {
		this.setState({ likesGraphLoading: true });

		let { likesFrom, likesTo } = this.state;
		likesFrom = moment(likesFrom).format("YYYY-MM-DD");
		likesTo = moment(likesTo).format("YYYY-MM-DD");

		try {
			const likesData = await Axios.get(
				`${config.PLATFORM_API}/stats/likes?from=${likesFrom}&to=${likesTo}`,
				{
					headers: {
						Authorization: UserService.getAuthToken()
					}
				}
			);
			this.setState({ likes: likesData.data });
		} catch (error) {
			console.log(error);
		}
		this.setState({ likesGraphLoading: false });
	}

	startTimeSinceReloadInterval() {
		this.checkReloadTimeInterval = setInterval(() => {
			const { reloadTime } = this.state;
			const minutesElapsed = moment().diff(reloadTime, "minutes");
			this.setState({ timeSinceReload: minutesElapsed });
		}, 60000);
	}
	componentDidMount() {
		this.startTimeSinceReloadInterval();
		this.fetchDashboardData();
		this.fetchCompletionsData();
		this.fetchLikesData();
		this.setState({ impressions: {} }, this.fetchImpressionsData);
	}
	componentWillUnmount() {
		clearInterval(this.checkReloadTimeInterval);
	}
	render() {
		const {
			totalLikes,
			totalQrScans,
			totalCompletions,
			totalImpressions,
			unauthorized,
			loading,
			timeSinceReload,
			completions,
			likes,
			impressions,
			impressionsFrom,
			impressionsTo,
			completionsFrom,
			completionsTo,
			likesFrom,
			likesTo,
			completionsGraphLoading,
			likesGraphLoading,
			impressionsGraphLoading
		} = this.state;

		if (unauthorized) {
			return <Redirect to="login" />;
		}
		if (!UserService.isSubscribed()) {
			return <Redirect to="/admin/purchase" />;
		}
		return (
			<div className="content">
				<Row>
					<Col lg="3" md="6" sm="6">
						<Card className="card-stats">
							<CardBody>
								<Row>
									<Col md="4" xs="5">
										<div className="icon-big text-center icon-warning">
											<i className="nc-icon nc-single-02 text-warning" />
										</div>
									</Col>
									<Col md="8" xs="7">
										<div className="numbers">
											<p className="card-category">Total Impressions</p>
											<CardTitle tag="div">
												{loading ? (
													<Spinner color="primary" />
												) : (
													totalImpressions
												)}
											</CardTitle>
											<p />
										</div>
									</Col>
								</Row>
							</CardBody>
							<CardFooter>
								<hr />
								<div className="stats">
									<i className="fas fa-sync-alt" />
									Updated{" "}
									{timeSinceReload > 0
										? timeSinceReload === 1
											? `${timeSinceReload} minute ago!`
											: `${timeSinceReload} minutes ago!`
										: "just now!"}
								</div>
							</CardFooter>
						</Card>
					</Col>
					<Col lg="3" md="6" sm="6">
						<Card className="card-stats">
							<CardBody>
								<Row>
									<Col md="4" xs="5">
										<div className="icon-big text-center icon-warning">
											<i className="nc-icon nc-favourite-28 text-primary" />
										</div>
									</Col>
									<Col md="8" xs="7">
										<div className="numbers">
											<p className="card-category">Total Likes</p>
											<CardTitle tag="div">
												{" "}
												{loading ? <Spinner color="primary" /> : totalLikes}
											</CardTitle>
											<p />
										</div>
									</Col>
								</Row>
							</CardBody>
							<CardFooter>
								<hr />
								<div className="stats">
									<i className="fas fa-sync-alt" />
									Updated{" "}
									{timeSinceReload > 0
										? timeSinceReload === 1
											? `${timeSinceReload} minute ago!`
											: `${timeSinceReload} minutes ago!`
										: "just now!"}
								</div>
							</CardFooter>
						</Card>
					</Col>
					<Col lg="3" md="6" sm="6">
						<Card className="card-stats">
							<CardBody>
								<Row>
									<Col md="4" xs="5">
										<div style={{ marginTop: 8, marginLeft: 6 }}>
											<img
												src="/img/qr-code.svg"
												width="42px"
												alt="QR code icon"
											/>
										</div>
									</Col>
									<Col md="8" xs="7">
										<div className="numbers">
											<p className="card-category">Total QR Scans</p>
											<CardTitle tag="div">
												{loading ? <Spinner color="primary" /> : totalQrScans}
											</CardTitle>
											<p />
										</div>
									</Col>
								</Row>
							</CardBody>
							<CardFooter>
								<hr />
								<div className="stats">
									<i className="far fa-clock" />
									Updated{" "}
									{timeSinceReload > 0
										? timeSinceReload === 1
											? `${timeSinceReload} minute ago!`
											: `${timeSinceReload} minutes ago!`
										: "just now!"}
								</div>
							</CardFooter>
						</Card>
					</Col>
					<Col lg="3" md="6" sm="6">
						<Card className="card-stats">
							<CardBody>
								<Row>
									<Col md="4" xs="5">
										<div className="icon-big text-center icon-warning">
											<i className="nc-icon nc-vector text-danger" />
										</div>
									</Col>
									<Col md="8" xs="7">
										<div className="numbers">
											<p className="card-category">Total Completions</p>
											<CardTitle tag="div">
												{loading ? (
													<Spinner color="primary" />
												) : (
													totalCompletions
												)}
											</CardTitle>
											<p />
										</div>
									</Col>
								</Row>
							</CardBody>
							<CardFooter>
								<hr />
								<div className="stats">
									<i className="far fa-clock" />
									Updated{" "}
									{timeSinceReload > 0
										? timeSinceReload === 1
											? `${timeSinceReload} minute ago!`
											: `${timeSinceReload} minutes ago!`
										: "just now!"}
								</div>
							</CardFooter>
						</Card>
					</Col>
				</Row>
				<Row>
					<Col md="12">
						<Card>
							<CardHeader>
								<CardTitle tag="h5">Total Impressions</CardTitle>
								<div className="InputFromTo">
									Date Range:{" "}
									<DayPickerInput
										value={impressionsFrom}
										placeholder=" From"
										format="LL"
										formatDate={formatDate}
										parseDate={parseDate}
										dayPickerProps={{
											selectedDays: [
												impressionsFrom,
												{ from: impressionsFrom, to: impressionsTo }
											],
											disabledDays: { after: impressionsTo },
											modifiers: { start: impressionsFrom, end: impressionsTo },
											toMonth: impressionsTo,
											numberOfMonths: 2,
											onDayClick: () => this.impressionsTo.getInput().focus()
										}}
										onDayChange={(from) =>
											this.handleFromChange(from, "impressions")
										}
									/>{" "}
									—{" "}
									<span className="InputFromTo-to">
										<DayPickerInput
											ref={(el) => (this.impressionsTo = el)}
											value={impressionsTo}
											placeholder=" To"
											format="LL"
											formatDate={formatDate}
											parseDate={parseDate}
											dayPickerProps={{
												selectedDays: [
													impressionsFrom,
													{ from: impressionsFrom, to: impressionsTo }
												],
												disabledDays: { before: impressionsFrom },
												modifiers: {
													start: impressionsFrom,
													end: impressionsTo
												},
												month: impressionsFrom,
												fromMonth: impressionsFrom,
												numberOfMonths: 2
											}}
											onDayChange={(to) =>
												this.handleToChange(to, "impressions")
											}
										/>
									</span>{" "}
									<button
										className="btn-round btn-icon btn btn-outline-primary btn-sm"
										onClick={() => this.fetchImpressionsData()}
									>
										<i
											className="nc-icon nc-minimal-right"
											style={{ fontWeight: "bolder" }}
										></i>
									</button>
								</div>
							</CardHeader>
							<CardBody>
								{impressionsGraphLoading ? (
									<div
										style={{
											display: "flex",
											justifyContent: "center",
											height: "12rem",
											alignItems: "center"
										}}
									>
										<Spinner
											type="grow"
											style={{ width: "10rem", height: "10rem" }}
											color="primary"
										/>
									</div>
								) : (
									<Line
										datasetKeyProvider={() => Math.random()}
										data={impressions}
										options={dashboard24HoursPerformanceChart.options}
										width={400}
										height={100}
									/>
								)}
							</CardBody>
							<CardFooter>
								<hr />
								<div className="stats">
									<i className="fa fa-history" /> Updated{" "}
									{timeSinceReload > 0
										? timeSinceReload === 1
											? `${timeSinceReload} minute ago!`
											: `${timeSinceReload} minutes ago!`
										: "just now!"}
								</div>
							</CardFooter>
						</Card>
					</Col>
				</Row>
				<Row>
					<Col md="12">
						<Card>
							<CardHeader>
								<CardTitle tag="h5">Total Completions</CardTitle>
								<div className="InputFromTo">
									Date Range:{" "}
									<DayPickerInput
										value={completionsFrom}
										placeholder=" From"
										format="LL"
										formatDate={formatDate}
										parseDate={parseDate}
										dayPickerProps={{
											selectedDays: [
												completionsFrom,
												{ from: completionsFrom, to: completionsTo }
											],
											disabledDays: { after: completionsTo },
											toMonth: completionsTo,
											modifiers: { start: completionsFrom, end: completionsTo },
											numberOfMonths: 2,
											onDayClick: () => this.completionsTo.getInput().focus()
										}}
										onDayChange={(from) =>
											this.handleFromChange(from, "completions")
										}
									/>{" "}
									—{" "}
									<span className="InputFromTo-to">
										<DayPickerInput
											ref={(el) => (this.completionsTo = el)}
											value={completionsTo}
											placeholder=" To"
											format="LL"
											formatDate={formatDate}
											parseDate={parseDate}
											dayPickerProps={{
												selectedDays: [
													completionsFrom,
													{ from: completionsFrom, to: completionsTo }
												],
												disabledDays: { before: completionsFrom },
												modifiers: {
													start: completionsFrom,
													end: completionsTo
												},
												month: completionsFrom,
												fromMonth: completionsFrom,
												numberOfMonths: 2
											}}
											onDayChange={(to) =>
												this.handleToChange(to, "completions")
											}
										/>
									</span>{" "}
									<button
										className="btn-round btn-icon btn btn-outline-primary btn-sm"
										onClick={() => this.fetchCompletionsData()}
									>
										<i
											className="nc-icon nc-minimal-right"
											style={{ fontWeight: "bolder" }}
										></i>
									</button>
								</div>
							</CardHeader>
							<CardBody>
								{completionsGraphLoading ? (
									<div
										style={{
											display: "flex",
											justifyContent: "center",
											height: "12rem",
											alignItems: "center"
										}}
									>
										<Spinner
											type="grow"
											style={{ width: "10rem", height: "10rem" }}
											color="primary"
										/>
									</div>
								) : (
									<Line
										datasetKeyProvider={() => Math.random()}
										data={completions}
										options={dashboard24HoursPerformanceChart.options}
										width={400}
										height={100}
									/>
								)}
							</CardBody>
							<CardFooter>
								<hr />
								<div className="stats">
									<i className="fa fa-history" /> Updated{" "}
									{timeSinceReload > 0
										? timeSinceReload === 1
											? `${timeSinceReload} minute ago!`
											: `${timeSinceReload} minutes ago!`
										: "just now!"}
								</div>
							</CardFooter>
						</Card>
					</Col>
				</Row>
				<Row>
					<Col md="12">
						<Card>
							<CardHeader>
								<CardTitle tag="h5">Total Likes</CardTitle>
								<div className="InputFromTo">
									Date Range:{" "}
									<DayPickerInput
										value={likesFrom}
										placeholder=" From"
										format="LL"
										formatDate={formatDate}
										parseDate={parseDate}
										dayPickerProps={{
											selectedDays: [
												likesFrom,
												{ from: likesFrom, to: likesTo }
											],
											disabledDays: { after: likesTo },
											toMonth: likesTo,
											modifiers: { start: likesFrom, end: likesTo },
											numberOfMonths: 2,
											onDayClick: () => this.likesTo.getInput().focus()
										}}
										onDayChange={(from) => this.handleFromChange(from, "likes")}
									/>{" "}
									—{" "}
									<span className="InputFromTo-to">
										<DayPickerInput
											ref={(el) => (this.likesTo = el)}
											value={likesTo}
											placeholder=" To"
											format="LL"
											formatDate={formatDate}
											parseDate={parseDate}
											dayPickerProps={{
												selectedDays: [
													likesFrom,
													{ from: likesFrom, to: likesTo }
												],
												disabledDays: { before: likesFrom },
												modifiers: { start: likesFrom, end: likesTo },
												month: likesFrom,
												fromMonth: likesFrom,
												numberOfMonths: 2
											}}
											onDayChange={(to) => this.handleToChange(to, "likes")}
										/>
									</span>{" "}
									<button
										className="btn-round btn-icon btn btn-outline-primary btn-sm"
										onClick={() => this.fetchLikesData()}
									>
										<i
											className="nc-icon nc-minimal-right"
											style={{ fontWeight: "bolder" }}
										></i>
									</button>
								</div>
							</CardHeader>
							<CardBody>
								{likesGraphLoading ? (
									<div
										style={{
											display: "flex",
											justifyContent: "center",
											height: "12rem",
											alignItems: "center"
										}}
									>
										<Spinner
											type="grow"
											style={{ width: "10rem", height: "10rem" }}
											color="primary"
										/>
									</div>
								) : (
									<Line
										datasetKeyProvider={() => Math.random()}
										data={likes}
										options={dashboard24HoursPerformanceChart.options}
										width={400}
										height={100}
									/>
								)}
							</CardBody>
							<CardFooter>
								<hr />
								<div className="stats">
									<i className="fa fa-history" /> Updated{" "}
									{timeSinceReload > 0
										? timeSinceReload === 1
											? `${timeSinceReload} minute ago!`
											: `${timeSinceReload} minutes ago!`
										: "just now!"}
								</div>
							</CardFooter>
						</Card>
					</Col>
				</Row>
			</div>
		);
	}
}

export default Dashboard;
