// Provides access to user data stored in local storage
const UserService = {
	/**
	 * Get the user data from local storage
	 * @returns {Object|null} Returns the user data, or null if not found
	 */
	get() {
		const userData = localStorage.getItem("ahia_user");
		try {
			if (userData) {
				return JSON.parse(userData);
			}
			return null;
		} catch (error) {
			return null;
		}
	},

	/**
	 * Set the user object in local storage for easy access
	 * @param {Object} userData - The user data response object from /api/user/login
	 */
	set(userData) {
		localStorage.setItem("ahia_user", JSON.stringify(userData));
	},

	/**
	 * Clear user data from local storage
	 */
	clear() {
		localStorage.removeItem("ahia_user");
	},
	/**
	 * Get the auth token for the user
	 * @returns {String} The auth token, or "" if not found
	 */
	getAuthToken() {
		let userData = this.get();
		return userData !== null ? userData.auth_token : "";
	},

	/**
	 * Determine if the user is logged in or not. Note that further checks still need to be done
	 * to find out of the user's authorization token is actually valid.
	 * @returns {Boolean} True if a user object was found, false otherwise
	 */
	isLoggedIn() {
		let userData = this.get();

		if (userData !== null) {
			return true;
		}

		return false;
	},
	isSubscribed() {
		if (this.get() && this.get().GroupPermission.id === 1) {
			return true;
		}
		return this.get()
			? this.get().PromotionPurchase
				? ["1", "2", "3"].includes(this.get().PromotionPurchase.type)
					? true
					: false
				: false
			: false;
	},
};

export default UserService;
