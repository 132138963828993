import React from "react";
import { Container, Row } from "reactstrap";
import * as Icons from "react-feather";

class Footer extends React.Component {
	render() {
		return (
			<footer
				className={"footer" + (this.props.default ? " footer-default" : "")}
			>
				<Container fluid={this.props.fluid ? true : false}>
					<Row>
						<div className="credits ml-auto">
							<div className="copyright">
								&copy; {1900 + new Date().getYear()}, made with{" "}
								<Icons.Heart fill="#ff5a5f" color="#ff5a5f" size="16px" /> by
								Ahia! Digital
							</div>
						</div>
					</Row>
				</Container>
			</footer>
		);
	}
}

export default Footer;
