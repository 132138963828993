import React, { Component } from "react";
import {
	Button,
	TextField,
	Container,
	Dialog,
	DialogContent,
	DialogActions,
	DialogTitle,
	Slide,
	colors
} from "@material-ui/core";
import SweetAlert from "react-bootstrap-sweetalert";
import Axios from "axios";
import config from "../../config/config";
import { Redirect, withRouter } from "react-router-dom";
import UserService from "../../services/UserService";
import StripeService from "../../services/StripeService";
import ReactGA from "react-ga4";
import "./login.css";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

class Login extends Component {
	state = {
		email: "",
		password: "",
		hasError: false,
		errorText: "",
		isLoggedIn: false,
		showForgotPasswordModal: false,
		forgotEmail: "",
		success: false
	};

	async requestToken(e) {
		e.preventDefault();
		const { forgotEmail } = this.state;

		try {
			await Axios.post(`${config.PLATFORM_API}/user/reset_password/get_token`, {
				email: forgotEmail
			});
			this.setState({
				success: true,
				infoText: "Reset token was sent to your email"
			});
			this.setState({ showForgotPasswordModal: false, forgotEmail: "" });
		} catch (error) {
			this.setState({ hasError: true, errorText: "Error requesting token" });
		}
	}
	async handleOnSignUpClick() {
		ReactGA.event({
			category: "button_click",
			action: "register",
		});
		try {
			await StripeService.redirectToStripePayment();
		} catch (error) {
			console.log(error);
		}
	}

	async handleLogin(e) {
		e.preventDefault();
		ReactGA.event({
			category: "button_click",
			action: "login",
		});
		const { email, password } = this.state;
		try {
			const user = await Axios.post(`${config.PLATFORM_API}/user/login`, {
				email,
				password,
				admin: true
			});
			UserService.set(user.data);

			this.setState({ isLoggedIn: true });
		} catch (error) {
			this.setState({ hasError: true, errorText: "Something went wrong" });
		}
	}
	render() {
		const {
			email,
			password,
			hasError,
			errorText,
			showForgotPasswordModal,
			forgotEmail,
			success,
			infoText
		} = this.state;
		if (UserService.get()) {
			return <Redirect to="/admin/dashboard" />;
		}
		return (
			<div
				className="body"
				style={{
					background: `url("https://s3.amazonaws.com/cdn.ahia.com/img/ahia-banner.jpg") no-repeat`,
					backgroundSize: "cover",
					boxShadow: "rgba(0, 0, 0, 0.2) 0px 0px 0px 2060px inset"
				}}
			>
				<div
					style={{
						position: "absolute",
						left: "25px",
						top: "25px"
					}}
				>
					<img src="/img/logo.png" width="109px" alt="ahia logo" />
					<br />
					<span style={{ fontSize: 12 }}>
						The premier rideshare campaign
						<br />
						management platform.
					</span>
				</div>
				<SweetAlert
					title="Error"
					show={hasError}
					error
					confirmBtnText="Ok"
					confirmBtnCssClass="MuiButtonBase-root MuiButton-root ahia-login-button MuiButton-contained MuiButton-containedSecondary"
					onConfirm={() => this.setState({ hasError: false, errorText: "" })}
				>
					{errorText}
				</SweetAlert>
				<SweetAlert
					title="Success"
					show={success}
					success
					confirmBtnText="Ok"
					confirmBtnCssClass="MuiButtonBase-root MuiButton-root ahia-login-button MuiButton-contained MuiButton-containedSecondary"
					onConfirm={() => {
						this.setState({ success: false, infoText: "" });
						this.props.history.push("/enter-token");
					}}
				>
					{infoText}
				</SweetAlert>
				<Container className="ahia-login-container">
					<form
						className="ahia-login-form-container"
						onSubmit={(e) => this.handleLogin(e)}
						autoComplete="off"
					>
						<h2 style={{ color: "white" }}>
							<strong>Login to access your account.</strong>
						</h2>
						<input
							id="email"
							label="Email"
							required
							className="ahia-login-field"
							value={email}
							onChange={(event) => this.setState({ email: event.target.value })}
							margin="normal"
							placeholder="Email"
						/>
						<br />
						<br />
						<input
							required
							id="password"
							label="Password"
							className="ahia-login-field"
							type="password"
							autoComplete="current-password"
							margin="normal"
							value={password}
							placeholder="Password"
							onChange={(event) =>
								this.setState({ password: event.target.value })
							}
						/>
						<div className="d-flex" style={{ marginTop: 24 }}>
							<Button
								variant="contained"
								type="submit"
								color="primary"
								className="ahia-login-button"
							>
								<strong>Login</strong>
							</Button>
							<Button
								variant="contained"
								type="button"
								color="primary"
								className="ahia-signup-button"
								onClick={() => this.handleOnSignUpClick()}
							>
								<strong>Register</strong>
							</Button>
						</div>
						<h6
							style={{
								display: "flex",
								justifyContent: "flex-end",
								color: "white",
								cursor: "pointer",
								marginTop: "10px"
							}}
							onClick={(e) => {
								e.preventDefault();
								ReactGA.event({
									category: "button_click",
									action: "forgot_password",
								});
								this.setState({ showForgotPasswordModal: true });
							}}
						>
							<strong>Forgot Password?</strong>
						</h6>
					</form>
				</Container>
				<Dialog
					fullWidth
					open={showForgotPasswordModal}
					TransitionComponent={Transition}
					keepMounted
					onClose={() =>
						this.setState({
							showForgotPasswordModal: false
						})
					}
					aria-labelledby="alert-dialog-slide-title"
					aria-describedby="alert-dialog-slide-description"
				>
					<div style={{ margin: "30px" }}>
						<DialogTitle id="alert-dialog-slide-title">
							Request Token
						</DialogTitle>
						<DialogContent>
							<TextField
								autoFocus
								id="femail"
								margin="dense"
								label="Email"
								type="email"
								fullWidth
								value={forgotEmail}
								onChange={(event) =>
									this.setState({ forgotEmail: event.target.value })
								}
							/>
						</DialogContent>
						<DialogActions>
							<Button
								style={{
									backgroundColor: colors.green[500],
									color: "#fff"
								}}
								variant="contained"
								color="primary"
								onClick={(e) => this.requestToken(e)}
							>
								Request Token
							</Button>
						</DialogActions>
					</div>
				</Dialog>
			</div>
		);
	}
}

export default withRouter(Login);
