import React, { Component } from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	TextField,
	DialogTitle,
	Slide,
	colors,
	MenuItem,
	Fab
} from "@material-ui/core";
import { Card, CardBody, Row, Col, Spinner } from "reactstrap";
import { Add as AddIcon } from "@material-ui/icons";
import Axios from "axios";
import { Redirect } from "react-router-dom";
import config from "../../config/config";
import UserService from "../../services/UserService";
import SweetAlert from "react-bootstrap-sweetalert";
import * as Icons from "react-feather";
import ReactGA from "react-ga4";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

class Users extends Component {
	state = {
		users: [],
		showUpdateModal: false,
		toUpdateUser: undefined,
		hasError: false,
		errorText: "",
		showInfo: false,
		infoText: "",
		id: "",
		firstName: "",
		lastName: "",
		phone: "",
		email: "",
		status: "",
		zip: "",
		showCreateModal: false,
		password: "",
		unauthorized: false,
		loading: false
	};
	componentDidMount() {
		this.fetchUsers();
	}

	async fetchUsers() {
		try {
			this.setState({ loading: true });
			const users = await Axios.get(`${config.PLATFORM_API}/user/fetch_all`, {
				headers: {
					Authorization: UserService.getAuthToken()
				}
			});
			this.setState({ users: users.data });
		} catch (error) {
			if (
				error.response &&
				error.response.status &&
				error.response.status === 401
			) {
				UserService.clear();
				this.setState({ unauthorized: true });
			}
			console.log(error);
		}
		this.setState({ loading: false });
	}
	async createUser() {
		const { firstName, lastName, phone, email, zip, password } = this.state;
		try {
			await Axios.post(`${config.PLATFORM_API}/user/signup`, {
				email,
				firstName,
				lastName,
				zip_postal: zip,
				phone,
				password
			});

			ReactGA.event({
				category: "button_click",
				action: "user_update",
			});
			this.setState({
				showCreateModal: false,
				showInfo: true,
				infoText: "User Created"
			});
			await this.fetchUsers();
		} catch (error) {
			if (
				error.response &&
				error.response.status &&
				error.response.status === 401
			) {
				UserService.clear();
				this.setState({ unauthorized: true });
			}
			console.log(error);
		}
	}
	async updateUser() {
		try {
			const { id, firstName, lastName, phone, email, status, zip } = this.state;

			await Axios.post(
				`${config.PLATFORM_API}/user/${id}/update_user`,
				{
					first_name: firstName,
					last_name: lastName,
					phone,
					email,
					zip_postal: zip,
					status
				},
				{
					headers: {
						Authorization: UserService.getAuthToken()
					}
				}
			);
			if (status !== "deleted") {
				ReactGA.event({
					category: "button_click",
					action: "user_update",
				});
			} else {
				ReactGA.event({
					category: "button_click",
					action: "user_delete",
				});
			}

			this.setState({
				showUpdateModal: false,
				showInfo: true,
				infoText: "User Updated"
			});
			await this.fetchUsers();
		} catch (error) {
			if (
				error.response &&
				error.response.status &&
				error.response.status === 401
			) {
				UserService.clear();
				this.setState({ unauthorized: true });
			}
			console.log(error);
			this.setState({ hasError: true, errorText: "Error Saving user!" });
		}
	}

	render() {
		const {
			users,
			showUpdateModal,
			phone,
			firstName,
			lastName,
			email,
			zip,
			status,
			hasError,
			errorText,
			showInfo,
			infoText,
			showCreateModal,
			password,
			unauthorized,
			loading
		} = this.state;
		if (unauthorized) {
			return <Redirect to="login" />;
		}
		if (!UserService.isSubscribed()) {
			return <Redirect to="/admin/purchase" />;
		}
		if (loading) {
			return (
				<div className="content spinner-center">
					<Spinner
						style={{ width: "14rem", height: "14rem" }}
						type="grow"
						color="success"
					/>
				</div>
			);
		}
		return (
			<div className="content">
				<div
					style={{ position: "fixed", bottom: 17, right: 17, zIndex: 10000000 }}
					onClick={() =>
						this.setState(
							{
								id: 0,
								firstName: "",
								lastName: "",
								phone: "",
								email: "",
								status: "active",
								zip: ""
							},
							() =>
								this.setState({
									showCreateModal: true,
									showUpdateModal: false
								})
						)
					}
				>
					<Fab style={{ backgroundColor: colors.green[500] }} aria-label="add">
						<AddIcon style={{ color: "white" }} />
					</Fab>
				</div>
				<div>
					<Row>
						<Col md="12">
							<Card>
								<CardBody>
									<Table stickyHeader>
										<TableHead>
											<TableRow>
												<TableCell>Sr. No.</TableCell>
												<TableCell>First Name</TableCell>
												<TableCell>Last Name</TableCell>
												<TableCell>User Id</TableCell>
												<TableCell>Email</TableCell>
												<TableCell>Phone</TableCell>
												<TableCell>Postal Code</TableCell>
												<TableCell>Status</TableCell>
												<TableCell>Actions</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{users.map((aUser, key) => (
												<TableRow key={aUser.id}>
													<TableCell>{key + 1}</TableCell>
													<TableCell>{aUser.first_name}</TableCell>
													<TableCell>{aUser.last_name}</TableCell>
													<TableCell>{aUser.id}</TableCell>
													<TableCell>{aUser.email}</TableCell>
													<TableCell>{aUser.phone}</TableCell>
													<TableCell>{aUser.zip_postal}</TableCell>
													<TableCell>{aUser.status}</TableCell>
													<TableCell>
														<Icons.Edit
															size="18"
															onClick={() =>
																this.setState(
																	{
																		id: aUser.id,
																		firstName: aUser.first_name,
																		lastName: aUser.last_name,
																		phone: aUser.phone,
																		email: aUser.email,
																		status: aUser.status,
																		zip: aUser.zip_postal
																	},
																	() =>
																		this.setState({
																			showUpdateModal: true,
																			showCreateModal: false
																		})
																)
															}
														/>{" "}
														<Icons.Trash2
															size="18"
															onClick={() =>
																this.setState(
																	{
																		id: aUser.id,
																		firstName: aUser.first_name,
																		lastName: aUser.last_name,
																		phone: aUser.phone,
																		email: aUser.email,
																		status: "deleted",
																		zip: aUser.zip_postal
																	},
																	this.updateUser
																)
															}
														/>
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</div>
				{(showUpdateModal || showCreateModal) && (
					<Dialog
						open={showUpdateModal || showCreateModal}
						TransitionComponent={Transition}
						keepMounted
						onClose={() =>
							this.setState({
								showUpdateModal: false,
								showCreateModal: false
							})
						}
						aria-labelledby="alert-dialog-slide-title"
						aria-describedby="alert-dialog-slide-description"
					>
						<div style={{ margin: "30px" }}>
							<DialogTitle id="alert-dialog-slide-title">
								{showCreateModal ? "Create" : "Update"} User
							</DialogTitle>
							<DialogContent>
								<TextField
									autoFocus
									margin="dense"
									label="First Name"
									type="text"
									fullWidth
									value={firstName}
									onChange={(event) =>
										this.setState({ firstName: event.target.value })
									}
								/>
								<TextField
									autoFocus
									margin="dense"
									label="Last Name"
									type="text"
									fullWidth
									value={lastName}
									onChange={(event) =>
										this.setState({ lastName: event.target.value })
									}
								/>
								<TextField
									autoFocus
									margin="dense"
									label="Email Address"
									type="email"
									autoComplete="off"
									fullWidth
									value={email}
									onChange={(event) =>
										this.setState({ email: event.target.value })
									}
								/>
								{showCreateModal && (
									<div>
										<TextField
											autoFocus
											margin="dense"
											label="Password"
											type="password"
											autoComplete="off"
											fullWidth
											value={password}
											onChange={(event) =>
												this.setState({ password: event.target.value })
											}
										/>
										{/* <TextField
									autoFocus
									margin="dense"
									id="name"
									label="Confirm "
									type="email"
									fullWidth
									value={email}
									onChange={event =>
										this.setState({ confirmPassword: event.target.value })
									}
								/> */}
									</div>
								)}
								<TextField
									autoFocus
									margin="dense"
									label="Phone"
									type="number"
									fullWidth
									value={phone}
									onChange={(event) =>
										this.setState({ phone: event.target.value })
									}
								/>
								<TextField
									autoFocus
									margin="dense"
									label="Postal Code"
									type="text"
									fullWidth
									value={zip}
									onChange={(event) =>
										this.setState({ zip: event.target.value })
									}
								/>
								{showUpdateModal && (
									<TextField
										value={status}
										fullWidth
										select
										label="Status"
										onChange={(event) =>
											this.setState({ status: event.target.value })
										}
									>
										<MenuItem value={"active"}>Active</MenuItem>
										<MenuItem value={"inactive"}>Inactive</MenuItem>
										<MenuItem value={"deleted"}>Deleted</MenuItem>
									</TextField>
								)}
							</DialogContent>
							<DialogActions>
								{showUpdateModal && (
									<Button
										onClick={() => this.updateUser()}
										style={{
											backgroundColor: colors.green[500],
											color: "#fff"
										}}
										variant="contained"
									>
										Save
									</Button>
								)}
								{showCreateModal && (
									<Button
										onClick={() => this.createUser()}
										style={{
											backgroundColor: colors.green[500],
											color: "#fff"
										}}
										variant="contained"
									>
										Create
									</Button>
								)}
								<Button
									onClick={() =>
										this.setState({
											showUpdateModal: false,
											showCreateModal: false
										})
									}
									color="secondary"
									variant="contained"
								>
									Cancel
								</Button>
							</DialogActions>
						</div>
						<SweetAlert
							title="Error"
							show={hasError}
							error
							confirmBtnText="Ok"
							confirmBtnCssClass="MuiButtonBase-root MuiButton-root ahia-login-button MuiButton-contained MuiButton-containedSecondary"
							onConfirm={() =>
								this.setState({ hasError: false, errorText: "" })
							}
						>
							{errorText}
						</SweetAlert>
						<SweetAlert
							title="Success"
							show={showInfo}
							success
							confirmBtnText="Ok"
							confirmBtnCssClass="MuiButtonBase-root MuiButton-root ahia-login-button MuiButton-contained MuiButton-containedPrimary"
							onConfirm={() => this.setState({ showInfo: false, infoText: "" })}
						>
							{infoText}
						</SweetAlert>
					</Dialog>
				)}
			</div>
		);
	}
}

export default Users;
