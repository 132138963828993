import React, { Component } from "react";
import UserService from "../../services/UserService";
import { Redirect } from "react-router-dom";
import Axios from "axios";
import StripeService from "../../services/StripeService";
import config from "../../config/config";
import SweetAlert from "react-bootstrap-sweetalert";

import "./buy.css";
class Promotions extends Component {
	state = {
		unauthorized: false,
		toActivatePlan: null,
		warning: false,
		error: false,
		success: false,
	};

	getButtonClassName(type) {
		return this.whichSubscriptionActive() === type
			? `pricing-button is-featured cursor-no-allow`
			: "pricing-button";
	}

	whichSubscriptionActive() {
		return UserService.get()
			? UserService.get().MonthlySubscription
				? UserService.get().MonthlySubscription.type
				: "0"
			: "0";
	}
	async getUserData() {
		try {
			const user = await Axios.post(
				`${config.PLATFORM_API}/user/logged_in_user`,
				{
					email: UserService.get().email,
				},
				{
					headers: { Authorization: UserService.getAuthToken() },
				}
			);
			UserService.set(user.data.user);
		} catch (error) {
			console.log(error);
		}
	}
	componentDidMount() {
		this.getUserData();
	}
	async activatePlan() {
		try {
			const { toActivatePlan } = this.state;
			if (!toActivatePlan) {
				return;
			}
			const data = await StripeService.redirectToByPromotionPayment(
				UserService.get().email,
				toActivatePlan
			);
			if (data.subscription_update) {
				this.setState({ toActivatePlan: null, success: true });
			}
		} catch (error) {
			if (
				error.response &&
				error.response.status &&
				error.response.status === 401
			) {
				UserService.clear();
				this.setState({ unauthorized: true });
			}
			console.log(error);
			this.setState({ hasError: true, errorText: "Error Saving Promotions!" });
		}
	}
	render() {
		const { unauthorized, warning, success, error } = this.state;

		if (unauthorized) {
			return <Redirect to="login" />;
		}

		return (
			<div className="background content">
				<div className="container">
					<div className="panel pricing-table">
						<div className="pricing-plan">
							<img
								src="https://s22.postimg.cc/8mv5gn7w1/paper-plane.png"
								alt=""
								className="pricing-img"
							/>
							<h2 className="pricing-header">Main Street Package</h2>
							<ul className="pricing-features">
								<li className="pricing-features-item">1000 Promotions</li>
								<li className="pricing-features-item">
									Add/Update/Delete any promotion
								</li>
							</ul>
							<span className="pricing-price">
								$100/<span style={{ textTransform: "lowercase" }}>mo.</span>
							</span>
							<button
								onClick={() => {
									this.setState({ toActivatePlan: "1", warning: true });
								}}
								disabled={this.whichSubscriptionActive() === "1"}
								className={this.getButtonClassName("1")}
							>
								{this.whichSubscriptionActive() === "1"
									? `Activated`
									: "Activate"}
							</button>
						</div>

						<div className="pricing-plan">
							<img
								src="https://s28.postimg.cc/ju5bnc3x9/plane.png"
								alt=""
								className="pricing-img"
							/>
							<h2 className="pricing-header">Hometown Package</h2>
							<ul className="pricing-features">
								<li className="pricing-features-item">3000 promotions</li>
								<li className="pricing-features-item">
									Add/Update/Delete any promotion
								</li>
							</ul>
							<span className="pricing-price">
								$210/<span style={{ textTransform: "lowercase" }}>mo.</span>
							</span>
							<button
								disabled={this.whichSubscriptionActive() === ""}
								className={this.getButtonClassName("2")}
								onClick={() => {
									this.setState({ toActivatePlan: "2", warning: true });
								}}
							>
								{this.whichSubscriptionActive() === "2"
									? `Activated`
									: "Activate"}
							</button>
						</div>

						<div className="pricing-plan">
							<img
								src="https://s21.postimg.cc/tpm0cge4n/space-ship.png"
								alt=""
								className="pricing-img"
							/>
							<h2 className="pricing-header">Metropolitan Package</h2>
							<ul className="pricing-features">
								<li className="pricing-features-item">5000 promotions</li>
								<li className="pricing-features-item">
									Add/Update/Delete any promotion
								</li>
							</ul>
							<span className="pricing-price">
								$250/<span style={{ textTransform: "lowercase" }}>mo.</span>
							</span>
							<button
								disabled={this.whichSubscriptionActive() === "3"}
								className={this.getButtonClassName("3")}
								onClick={() => {
									this.setState({ toActivatePlan: "3", warning: true });
								}}
							>
								{this.whichSubscriptionActive() === "3"
									? `Activated`
									: "Activate"}
							</button>
						</div>
					</div>
				</div>
				<SweetAlert
					warning
					showCancel
					show={warning}
					confirmBtnText="Yes!"
					title="Are you sure?"
					onConfirm={() => {
						this.setState({ warning: false });
						this.activatePlan();
					}}
					onCancel={() => this.setState({ warning: false })}
				>
					You are going to change your subscription
				</SweetAlert>
				<SweetAlert
					title="Error"
					show={error}
					error
					confirmBtnText="Ok"
					confirmBtnCssClass="MuiButtonBase-root MuiButton-root ahia-login-button MuiButton-contained MuiButton-containedSecondary"
					onConfirm={() => this.setState({ error: false })}
				>
					An unknown error occured. Please try again or contact us at
					ahiadigital@gmail.com
				</SweetAlert>
				<SweetAlert
					title="Success"
					show={success}
					success
					confirmBtnText="Ok"
					confirmBtnCssClass="MuiButtonBase-root MuiButton-root ahia-login-button MuiButton-contained MuiButton-containedSecondary"
					onConfirm={() => {
						this.setState({ success: false });
						this.props.history.push("/admin/dashboard");
					}}
				>
					Your subscription is being updated. Please wait upto 24 hours to see
					the changes here!
				</SweetAlert>
			</div>
		);
	}
}

export default Promotions;
