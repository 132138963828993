import React, { Component } from "react";
import { Button, Container } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import Axios from "axios";
import config from "../../config/config";

class EnterToken extends Component {
	state = {
		token: "",
		password: "",
		confirmPassword: "",
		hasError: false,
		errorText: "",
		success: false,
		infoText: ""
	};
	async resetPassword(e) {
		e.preventDefault();
		const { token, password, confirmPassword } = this.state;
		if (password !== confirmPassword) {
			this.setState({ hasError: true, errorText: "Passwords mismatch" });
			return;
		}
		try {
			await Axios.post(`${config.PLATFORM_API}/user/reset_password/${token}`, {
				password
			});
			this.setState({ success: true, infoText: "Password reset complete" });
		} catch (error) {
			console.log(error);
			this.setState({
				hasError: true,
				errorText: "Error resetting password. Please try again"
			});
		}
	}
	render() {
		const {
			token,
			password,
			confirmPassword,
			hasError,
			errorText,
			success,
			infoText
		} = this.state;
		return (
			<div
				style={{
					background: `url("https://s3.amazonaws.com/cdn.ahia.com/img/ahia-banner.jpg") no-repeat`,
					backgroundSize: "cover",
					boxShadow: "rgba(0, 0, 0, 0.2) 0px 0px 0px 2060px inset"
				}}
			>
				<div
					style={{
						position: "absolute",
						left: "25px",
						top: "25px"
					}}
				>
					<img src="/img/logo.png" width="109px" alt="ahia logo" />
					<br />
					<span style={{ fontSize: 12 }}>
						The premier rideshare campaign
						<br />
						management platform.
					</span>
				</div>
				<SweetAlert
					title="Error"
					show={hasError}
					error
					confirmBtnText="Ok"
					confirmBtnCssClass="MuiButtonBase-root MuiButton-root ahia-login-button MuiButton-contained MuiButton-containedSecondary"
					onConfirm={() => this.setState({ hasError: false, errorText: "" })}
				>
					{errorText}
				</SweetAlert>
				<SweetAlert
					title="Success"
					show={success}
					success
					confirmBtnText="Ok"
					confirmBtnCssClass="MuiButtonBase-root MuiButton-root ahia-login-button MuiButton-contained MuiButton-containedSecondary"
					onConfirm={() => {
						this.setState({ success: false, infoText: "" });
						this.props.history.push("/login");
					}}
				>
					{infoText}
				</SweetAlert>
				<Container className="ahia-login-container">
					<form
						className="ahia-login-form-container"
						style={{ width: 600 }}
						onSubmit={(e) => this.resetPassword(e)}
						autoComplete="off"
					>
						<h2 style={{ color: "white" }}>
							<strong>Reset Password</strong>
						</h2>

						<input
							id="token"
							label="Token"
							required
							className="ahia-login-field"
							value={token}
							type="number"
							minLength="6"
							placeholder="Token"
							onChange={(event) => this.setState({ token: event.target.value })}
							margin="normal"
						/>
						<br />
						<br />
						<input
							required
							id="password"
							label="Password"
							className="ahia-login-field"
							type="password"
							margin="normal"
							value={password}
							placeholder="New Password"
							onChange={(event) =>
								this.setState({ password: event.target.value })
							}
							minLength="6"
						/>
						<br />
						<br />
						<input
							required
							id="c"
							label="Confirm Password"
							className="ahia-login-field"
							type="password"
							margin="normal"
							placeholder="Confirm Password"
							value={confirmPassword}
							onChange={(event) =>
								this.setState({ confirmPassword: event.target.value })
							}
							minLength="6"
						/>
						<div className="d-flex" style={{ marginTop: 24 }}>
							<Button
								variant="contained"
								type="submit"
								color="primary"
								className="ahia-login-button"
							>
								<strong>Reset</strong>
							</Button>
						</div>
					</form>
				</Container>
			</div>
		);
	}
}

export default withRouter(EnterToken);
