import React, { Component } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	TextField,
	DialogTitle,
	colors,
	Fab,
	Select,
	MenuItem
} from "@material-ui/core";
import { Row, Col, Spinner, Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Add as AddIcon } from "@material-ui/icons";
import Axios from "axios";
import config from "../../config/config";
import UserService from "../../services/UserService";
import SweetAlert from "react-bootstrap-sweetalert";
import { Redirect } from "react-router-dom";
import * as Icons from "react-feather";
import DateTime from "react-datetime";
import moment from "moment";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { from_distances, to_distances } from "../../variables/distances";
import ReactGA from "react-ga4";

class Campaigns extends Component {
	state = {
		campaigns: [],
		showUpdateModal: false,
		hasError: false,
		errorText: "",
		showInfo: false,
		infoText: "",
		id: "",
		name: "",
		status: "",
		maxDevices: "",
		showCreateModal: false,
		redirect: false,
		loading: true,
		endTime: "",
		showPromotionModal: false,
		promotions: [],
		selectedPromotion: "",
		showDeletedInfo: false,
		infoDeletedText: "",
		hasDeletedError: false,
		errorDeletedText: "",
		toDistance: "",
		fromDistance: from_distances[0]
	};
	componentDidMount() {
		this.fetchCampaigns();
		// this.fetchPromotions();
	}
	async fetchCampaigns() {
		try {
			const campaigns = await Axios.get(
				`${config.PLATFORM_API}/campaigns/fetch`,
				{
					headers: {
						Authorization: UserService.getAuthToken()
					}
				}
			);
			this.setState({ campaigns: campaigns.data });
		} catch (error) {
			if (
				error.response &&
				error.response.status &&
				error.response.status === 401
			) {
				UserService.clear();
				this.setState({ unauthorized: true });
			}
			console.log(error);
		}
		this.setState({ loading: false });
	}
	async createCampaign() {
		const { name, endTime, maxDevices, toDistance, fromDistance } = this.state;
		try {
			await Axios.post(
				`${config.PLATFORM_API}/campaigns/create`,
				{
					name,
					end_time: endTime ? endTime.format("YYYY-MM-DD HH:mm:ss") : null,
					max_device_count: maxDevices || 0,
					from_distance: fromDistance ? fromDistance : null,
					to_distance: toDistance ? toDistance : null
				},
				{
					headers: { Authorization: UserService.getAuthToken() }
				}
			);

			ReactGA.event({
				category: "button_click",
				action: "campaign_create",
			});
			this.setState({
				showInfo: true,
				infoText: "Campaign Created"
			});
			await this.fetchCampaigns();
		} catch (error) {
			if (
				error.response &&
				error.response.status &&
				error.response.status === 401
			) {
				UserService.clear();
				this.setState({ unauthorized: true });
			}
			console.log(error);
			this.setState({ hasError: true, errorText: "Error Saving campaigns!" });
		}
	}
	async deleteCampaign() {
		try {
			const {
				id,
				name,
				endTime,
				status,
				maxDevices,
				fromDistance,
				toDistance
			} = this.state;

			await Axios.post(
				`${config.PLATFORM_API}/campaigns/${id}/update`,
				{
					id,
					name,
					end_time: endTime ? endTime.format("YYYY-MM-DD HH:mm:ss") : null,
					max_device_count: maxDevices || 0,
					from_distance: fromDistance ? fromDistance : null,
					to_distance: toDistance ? toDistance : null,
					status
				},
				{
					headers: {
						Authorization: UserService.getAuthToken()
					}
				}
			);

			ReactGA.event({
				category: "button_click",
				action: "campaign_delete",
			});
			this.setState({
				showDeletedInfo: true,
				infoDeletedText: "Campaign Deleted"
			});
			await this.fetchCampaigns();
		} catch (error) {
			if (
				error.response &&
				error.response.status &&
				error.response.status === 401
			) {
				UserService.clear();
				this.setState({ unauthorized: true });
			}
			console.log(error);
			this.setState({
				hasDeletedError: true,
				errorDeletedText: "Error deleting Campaign!"
			});
		}
	}
	async updateCampaign() {
		try {
			const {
				id,
				name,
				endTime,
				maxDevices,
				fromDistance,
				toDistance
			} = this.state;

			await Axios.post(
				`${config.PLATFORM_API}/campaigns/${id}/update`,
				{
					id,
					name,
					end_time: endTime ? endTime.format("YYYY-MM-DD HH:mm:ss") : null,
					max_device_count: maxDevices || 0,
					from_distance: fromDistance ? fromDistance : null,
					to_distance: toDistance ? toDistance : null
				},
				{
					headers: {
						Authorization: UserService.getAuthToken()
					}
				}
			);

			ReactGA.event({
				category: "button_click",
				action: "campaign_update",
			});
			this.setState({
				showInfo: true,
				infoText: "Campaign Updated"
			});
			await this.fetchCampaigns();
		} catch (error) {
			if (
				error.response &&
				error.response.status &&
				error.response.status === 401
			) {
				UserService.clear();
				this.setState({ unauthorized: true });
			}
			console.log(error);
			this.setState({ hasError: true, errorText: "Error Saving Campaign!" });
		}
	}
	async onPanelChange(expanded, campaignId) {
		try {
			if (expanded) {
				this.setState({ [`promotionsLoading${campaignId}`]: true });
				const promotions = await Axios.get(
					`${config.PLATFORM_API}/campaigns/${campaignId}/promotions`,
					{
						headers: {
							Authorization: UserService.getAuthToken()
						}
					}
				);
				this.setState({ [`promotions${campaignId}`]: promotions.data });
			} else {
				this.setState({ [`promotions${campaignId}`]: undefined });
			}
		} catch (error) {
			console.log(error);
		}
		this.setState({
			[`panelOpen${campaignId}`]: expanded,
			[`promotionsLoading${campaignId}`]: false
		});
	}
	async addPromotion() {
		this.setState({ addPromotionLoading: true });
		try {
			const { selectedPromotion, id } = this.state;
			await Axios.post(
				`${config.PLATFORM_API}/campaigns/${id}/add_promotions`,
				{
					promotion_id: selectedPromotion
				},
				{
					headers: {
						Authorization: UserService.getAuthToken()
					}
				}
			);
			await this.fetchPromotions();
		} catch (error) {
			console.log(error);
		}
		this.setState({ addPromotionLoading: false });
	}
	async fetchPromotions() {
		try {
			// this.setState({ loading: true });
			const promotions = await Axios.get(
				`${config.PLATFORM_API}/campaigns/unselected_promotions`,
				{
					headers: {
						Authorization: UserService.getAuthToken()
					}
				}
			);
			this.setState({
				promotions: promotions.data,
				selectedPromotion: promotions.data[0].id
			});
		} catch (error) {
			if (
				error.response &&
				error.response.status &&
				error.response.status === 401
			) {
				UserService.clear();
				this.setState({ unauthorized: true });
			}
			console.log(error);
		}
		this.setState({ loading: false });
	}
	handlePromotionChange(promotionId) {
		this.setState({ selectedPromotion: promotionId });
	}
	async deletePromotion(promotionId, campaignId) {
		try {
			this.setState({
				[`promotionsLoading${campaignId}`]: true,
				[`promotions${campaignId}`]: undefined
			});

			await Axios.post(
				`${config.PLATFORM_API}/campaigns/delete_promotions`,
				{
					promotion_id: promotionId
				},
				{
					headers: {
						Authorization: UserService.getAuthToken()
					}
				}
			);
			await this.onPanelChange(true, campaignId);
		} catch (error) {
			console.log(error);
		}
	}
	render() {
		const {
			campaigns,
			showUpdateModal,
			name,
			maxDevices,
			hasError,
			errorText,
			showInfo,
			infoText,
			showCreateModal,
			unauthorized,
			loading,
			endTime,
			showPromotionModal,
			promotions,
			addPromotionLoading,
			selectedPromotion,
			showDeletedInfo,
			infoDeletedText,
			hasDeletedError,
			errorDeletedText,
			toDistance,
			fromDistance
		} = this.state;
		if (unauthorized) {
			return <Redirect to="login" />;
		}
		if (!UserService.isSubscribed()) {
			return <Redirect to="/admin/purchase" />;
		}
		if (loading) {
			return (
				<div className="content spinner-center">
					<Spinner
						style={{ width: "14rem", height: "14rem" }}
						type="grow"
						color="success"
					/>
				</div>
			);
		}
		return (
			<div className="content">
				<div
					style={{ position: "fixed", bottom: 17, right: 17, zIndex: 10000000 }}
					onClick={() =>
						this.setState(
							{
								id: 0,
								name: "",
								maxDevices: "",
								fromDistance: "",
								toDistance: ""
							},
							() =>
								this.setState({
									showCreateModal: true,
									showUpdateModal: false
								})
						)
					}
				>
					<Fab
						style={{
							backgroundColor: colors.green[500]
						}}
						aria-label="add"
					>
						<AddIcon style={{ color: "white" }} />
					</Fab>
				</div>
				<div>
					{campaigns.map((aCampaign, key) => {
						const fromDistanceFind = from_distances.find(
							(aDistance) =>
								aDistance.value === parseInt(aCampaign.from_distance, 10)
						);
						const fromDistanceValue = fromDistanceFind
							? fromDistanceFind.label
							: "not set";

						const toDistanceFind = to_distances.find(
							(aDistance) =>
								aDistance.value === parseInt(aCampaign.to_distance, 10)
						);
						const toDistanceValue = toDistanceFind
							? toDistanceFind.label
							: "not set";

						return (
							<Row key={key}>
								<Col lg="12" md="12" sm="12">
									<ExpansionPanel
										style={{ marginBottom: "15px" }}
										expanded={this.state[`panelOpen${aCampaign.id}`]}
										onChange={(e, expanded) =>
											this.onPanelChange(expanded, aCampaign.id)
										}
									>
										<ExpansionPanelSummary
											expandIcon={<ExpandMoreIcon />}
											aria-controls="panel1a-content"
											id="panel1a-header"
										>
											<div style={{ width: "100%" }}>
												Campaign {key + 1} <br />
												<div>
													<b>Name:</b>{" "}
													{aCampaign.name ? (
														aCampaign.name
													) : (
														<i style={{ color: "gray" }}>Add a name...</i>
													)}
												</div>
												<div>
													<b>Max Devices: </b>
													{aCampaign.max_device_count || "not set"}
												</div>
												<div>
													<b>From Distance: </b>
													{fromDistanceValue}
												</div>
												<div>
													<b>To Distance: </b>
													{toDistanceValue}
												</div>
												<div>
													<b>End Time: </b>
													{moment(aCampaign.end_time).format(
														"YYYY-MM-DD HH:mm:ss"
													)}{" "}
												</div>
												<hr />
												<div>
													<Icons.Plus
														size="18"
														onClick={(e) => {
															e.preventDefault();
															e.stopPropagation();
															this.fetchPromotions();
															this.setState({
																id: aCampaign.id,
																showPromotionModal: true
															});
														}}
													/>{" "}
													<Icons.Edit
														size="18"
														onClick={(e) => {
															e.preventDefault();
															e.stopPropagation();

															this.setState(
																{
																	id: aCampaign.id,
																	name: aCampaign.name,
																	maxDevices: aCampaign.max_device_count
																		? parseInt(aCampaign.max_device_count, 10)
																		: "",
																	endTime: aCampaign.end_time
																		? moment(aCampaign.end_time)
																		: null,
																	fromDistance: aCampaign.from_distance || "",
																	toDistance: aCampaign.to_distance || ""
																},
																() =>
																	this.setState({
																		showUpdateModal: true,
																		showCreateModal: false
																	})
															);
														}}
													/>{" "}
													<Icons.Trash2
														size="18"
														onClick={(e) => {
															e.preventDefault();
															e.stopPropagation();
															this.setState(
																{
																	id: aCampaign.id,
																	status: "deleted"
																},
																this.deleteCampaign
															);
														}}
													/>
												</div>
											</div>
										</ExpansionPanelSummary>
										<ExpansionPanelDetails style={{ display: "block" }}>
											{this.state[`promotionsLoading${aCampaign.id}`] && (
												<div
													style={{
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
														height: "9rem",
														width: "100%"
													}}
												>
													<Spinner
														type="grow"
														style={{ width: "8rem", height: "8rem" }}
														color="success"
													/>
												</div>
											)}
											{this.state[`promotions${aCampaign.id}`] ? (
												<div>
													{this.state[`promotions${aCampaign.id}`].map(
														(aPromo, key1) => {
															return (
																<Breadcrumb key={key1}>
																	<BreadcrumbItem
																		active
																		style={{ width: "100%" }}
																	>
																		<div
																			key={key}
																			style={{
																				display: "flex",
																				justifyContent: "space-between"
																			}}
																		>
																			<div>{aPromo.name}</div>
																			<div>
																				<Icons.X
																					style={{ cursor: "pointer" }}
																					size="18"
																					onClick={(e) => {
																						e.preventDefault();
																						e.stopPropagation();

																						this.deletePromotion(
																							aPromo.id,
																							aCampaign.id
																						);
																					}}
																				/>
																			</div>
																		</div>
																	</BreadcrumbItem>
																</Breadcrumb>
															);
														}
													)}
												</div>
											) : (
												<div />
											)}
										</ExpansionPanelDetails>
									</ExpansionPanel>
								</Col>
							</Row>
						);
					})}
				</div>
				{(showUpdateModal || showCreateModal) && (
					<Dialog
						fullWidth
						open={showUpdateModal || showCreateModal}
						keepMounted
						onClose={() =>
							this.setState({
								showUpdateModal: false,
								showCreateModal: false
							})
						}
						aria-labelledby="alert-dialog-slide-title"
						aria-describedby="alert-dialog-slide-description"
					>
						<div style={{ margin: "30px" }}>
							<DialogTitle id="alert-dialog-slide-title">
								{showCreateModal ? "Create" : "Update"} Campaign
							</DialogTitle>
							<DialogContent>
								<form>
									<TextField
										autoFocus
										id="name"
										margin="dense"
										label="Name"
										type="text"
										fullWidth
										value={name || ""}
										onChange={(event) =>
											this.setState({ name: event.target.value })
										}
									/>
									<TextField
										id="max_devices"
										margin="dense"
										label="Max Devices"
										type="number"
										fullWidth
										value={maxDevices}
										onChange={(event) =>
											this.setState({ maxDevices: event.target.value })
										}
									/>
									<TextField
										value={fromDistance || 0}
										fullWidth
										select
										label="From Distance"
										onChange={(event) =>
											this.setState({ fromDistance: event.target.value })
										}
									>
										{from_distances.map((aDistance, key) => {
											return (
												<MenuItem key={key} value={aDistance.value}>
													{aDistance.label}
												</MenuItem>
											);
										})}
									</TextField>
									<TextField
										value={toDistance || 0}
										fullWidth
										select
										label="To Distance"
										onChange={(event) =>
											this.setState({ toDistance: event.target.value })
										}
									>
										{to_distances.map((aDistance, key) => {
											return (
												<MenuItem key={key} value={aDistance.value}>
													{aDistance.label}
												</MenuItem>
											);
										})}
									</TextField>
									<div
										style={{ marginBottom: "180px" }}
										className="MuiFormControl-root MuiTextField-root MuiFormControl-marginDense MuiFormControl-fullWidth"
									>
										<label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense MuiFormLabel-filled">
											End Time
										</label>
										<DateTime
											className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl"
											inputProps={{
												className:
													"MuiSelect-root MuiSelect-select MuiSelect-selectMenu MuiInputBase-input MuiInput-input MuiInputBase-inputSelect"
											}}
											defaultValue=""
											value={endTime}
											onChange={(value) => this.setState({ endTime: value })}
										/>
									</div>
								</form>
							</DialogContent>
							<DialogActions>
								{showUpdateModal && (
									<Button
										onClick={() => this.updateCampaign()}
										style={{
											backgroundColor: colors.green[500],
											color: "#fff"
										}}
										variant="contained"
										type="submit"
									>
										Save
									</Button>
								)}
								{showCreateModal && (
									<Button
										onClick={() => this.createCampaign()}
										style={{
											backgroundColor: colors.green[500],
											color: "#fff"
										}}
										variant="contained"
										type="submit"
									>
										Create
									</Button>
								)}
								<Button
									onClick={() =>
										this.setState({
											showUpdateModal: false,
											showCreateModal: false
										})
									}
									color="secondary"
									variant="contained"
								>
									Cancel
								</Button>
							</DialogActions>
						</div>
						<SweetAlert
							title="Error"
							show={hasError}
							error
							confirmBtnText="Ok"
							confirmBtnCssClass="MuiButtonBase-root MuiButton-root ahia-login-button MuiButton-contained MuiButton-containedSecondary"
							onConfirm={() =>
								this.setState({ hasError: false, errorText: "" })
							}
						>
							{errorText}
						</SweetAlert>
						<SweetAlert
							title="Success"
							show={showInfo}
							success
							confirmBtnText="Ok"
							confirmBtnCssClass="MuiButtonBase-root MuiButton-root ahia-login-button MuiButton-contained MuiButton-containedPrimary"
							onConfirm={() =>
								this.setState({
									showInfo: false,
									infoText: "",
									showCreateModal: false,
									showUpdateModal: false
								})
							}
						>
							{infoText}
						</SweetAlert>
					</Dialog>
				)}
				{showPromotionModal && (
					<Dialog
						open={showPromotionModal}
						keepMounted
						onClose={() =>
							this.setState({
								showPromotionModal: false
							})
						}
						aria-labelledby="alert-dialog-slide-title"
						aria-describedby="alert-dialog-slide-description"
					>
						<div style={{ margin: "30px" }}>
							<DialogTitle id="alert-dialog-slide-title">
								Add Promotion to Campaign
							</DialogTitle>
							<DialogContent>
								{addPromotionLoading && (
									<div
										style={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center"
										}}
									>
										<Spinner
											style={{ height: "7rem", width: "7rem" }}
											type="grow"
											color="success"
										/>
									</div>
								)}
								{!addPromotionLoading && (
									<form>
										<Select
											native
											value={selectedPromotion}
											onChange={(e) =>
												this.handlePromotionChange(e.target.value)
											}
											fullWidth
										>
											{promotions.map((aPromotion, key) => {
												return (
													<option key={key} value={aPromotion.id}>
														{aPromotion.name}
													</option>
												);
											})}
										</Select>
									</form>
								)}
							</DialogContent>
							<DialogActions>
								<Button
									onClick={() => this.addPromotion()}
									style={{
										backgroundColor: colors.green[500],
										color: "#fff"
									}}
									variant="contained"
									type="submit"
									disabled={addPromotionLoading}
								>
									Add Promotion
								</Button>
								<Button
									onClick={() => this.setState({ showPromotionModal: false })}
									style={{
										backgroundColor: colors.red[500],
										color: "#fff"
									}}
									variant="contained"
									disabled={addPromotionLoading}
								>
									Cancel
								</Button>
							</DialogActions>
						</div>
					</Dialog>
				)}
				<SweetAlert
					title="Error"
					show={hasDeletedError}
					error
					confirmBtnText="Ok"
					confirmBtnCssClass="MuiButtonBase-root MuiButton-root ahia-login-button MuiButton-contained MuiButton-containedSecondary"
					onConfirm={() =>
						this.setState({ hasDeletedError: false, errorDeletedText: "" })
					}
				>
					{errorDeletedText}
				</SweetAlert>
				<SweetAlert
					title="Success"
					show={showDeletedInfo}
					success
					confirmBtnText="Ok"
					confirmBtnCssClass="MuiButtonBase-root MuiButton-root ahia-login-button MuiButton-contained MuiButton-containedPrimary"
					onConfirm={() =>
						this.setState({
							showDeletedInfo: false,
							infoDeletedText: ""
						})
					}
				>
					{infoDeletedText}
				</SweetAlert>
			</div>
		);
	}
}

export default Campaigns;
