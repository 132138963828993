import React, { Component } from "react";
import { Container, Button } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Axios from "axios";
import config from "../../config/config";
import SweetAlert from "react-bootstrap-sweetalert";
import { PAYMENT_STATUS } from "../../constants/PaymentConstants";
import StripeService from "../../services/StripeService";

import "./signup.css";

class Signup extends Component {
	state = {
		firstName: "",
		lastName: "",
		password: "",
		confirmPassword: "",
		email: "",
		errorText: "",
		hasError: false,
		success: false,
		successText: "",
		paymentStatus: "",
		paymentErrorMessage: "",
		paymentErrorTitle: ""
	};
	handleOnLoginClick() {
		this.props.history.push("/login");
	}
	async handleOnSignupClick(event) {
		event.preventDefault();
		event.stopPropagation();

		const {
			email,
			password,
			confirmPassword,
			firstName,
			lastName
		} = this.state;
		if (password !== confirmPassword) {
			this.setState({ hasError: true, errorText: "Passwords do not match. " });
			return;
		}

		try {
			const emailExistsResponse = await Axios.get(
				`${config.PLATFORM_API}/user/check_email?email=${email}`
			);
			if (!emailExistsResponse.data.available) {
				this.setState({
					hasError: true,
					errorText:
						"This email has already been taken! If you made a payment using this email, please contact us at ahiadigital@gmail.com otherwise try different a email address."
				});
				return;
			}
			const paymentStatusResponse = await Axios.get(
				`${config.PLATFORM_API}/payments/membership_payment_status?email=${email}`
			);
			if (!paymentStatusResponse.data.status) {
				this.setState({
					paymentStatus: null,
					paymentErrorMessage:
						"Please purchase the membership by clicking Purchase to sign up for Ahia!",
					paymentErrorTitle: "Signup Failed!"
				});
				return;
			}
			if (paymentStatusResponse.data.status === PAYMENT_STATUS.pending) {
				this.setState({
					paymentStatus: PAYMENT_STATUS.pending,
					paymentErrorMessage:
						"Your payment is under process. Please try again after some time or contact us at ahiadigital@gmail.com.",
					paymentErrorTitle: " We are processing your Payment!"
				});
				return;
			}
			if (paymentStatusResponse.data.status === PAYMENT_STATUS.failed) {
				this.setState({
					paymentStatus: PAYMENT_STATUS.failed,
					paymentErrorMessage: `Your payment has filed! Please retry your payment by clicking "Retry Payment" or contact us at ahiadigital@gmail.com for more details.`,
					paymentErrorTitle: "Payment Failed!"
				});
				return;
			}

			await this.createUser(email, password, firstName, lastName);
		} catch (error) {
			this.setState({ hasError: true, errorText: error.response.data.error });
		}
	}
	async createUser(email, password, firstName, lastName) {
		await Axios.post(`${config.PLATFORM_API}/user/signup`, {
			email,
			password,
			firstName,
			lastName,
			admin: true
		});
		this.setState({
			success: true,
			successText: "Please login using the created user."
		});
	}
	async purchaseMembership() {
		this.setState({
			paymentErrorTitle: "",
			paymentStatus: "",
			paymentErrorMessage: ""
		});
		try {
			await StripeService.redirectToStripePayment();
		} catch (error) {
			this.setState({
				hasError: true,
				errorText:
					"Failed to redirect to Payments page. Please try again later or contact us at ahiadigital@gmail.com"
			});
		}
	}
	render() {
		const {
			email,
			password,
			firstName,
			lastName,
			confirmPassword,
			hasError,
			errorText,
			success,
			successText,
			paymentStatus,
			paymentErrorMessage,
			paymentErrorTitle
		} = this.state;
		return (
			<div
				className="body"
				style={{
					background: `url("https://s3.amazonaws.com/cdn.ahia.com/img/ahia-banner.jpg") no-repeat`,
					backgroundSize: "cover",
					boxShadow: "rgba(0, 0, 0, 0.2) 0px 0px 0px 2060px inset"
				}}
			>
				<div>
					<div
						style={{
							position: "absolute",
							left: "25px",
							top: "25px"
						}}
					>
						<img src="/img/logo.png" width="109px" alt="ahia logo" />
						<br />
						<span style={{ fontSize: 12 }}>
							The premier rideshare campaign
							<br />
							management platform.
						</span>
					</div>
					<SweetAlert
						title="Error"
						show={hasError}
						error
						confirmBtnText="Ok"
						confirmBtnCssClass="MuiButtonBase-root MuiButton-root ahia-login-button MuiButton-contained MuiButton-containedSecondary"
						onConfirm={() => this.setState({ hasError: false, errorText: "" })}
					>
						{errorText}
					</SweetAlert>
					<SweetAlert
						title={paymentErrorTitle}
						show={paymentStatus === null}
						error
						confirmBtnText="Purchase"
						confirmBtnCssClass="MuiButtonBase-root MuiButton-root ahia-login-button MuiButton-contained MuiButton-containedSecondary"
						onConfirm={() => this.purchaseMembership()}
					>
						{paymentErrorMessage}
					</SweetAlert>
					<SweetAlert
						title={paymentErrorTitle}
						show={paymentStatus === PAYMENT_STATUS.pending}
						warning
						confirmBtnText="Ok"
						confirmBtnCssClass="MuiButtonBase-root MuiButton-root ahia-login-button MuiButton-contained MuiButton-containedSecondary"
						onConfirm={() =>
							this.setState({
								paymentErrorTitle: "",
								paymentStatus: "",
								paymentErrorMessage: ""
							})
						}
					>
						{paymentErrorMessage}
					</SweetAlert>
					<SweetAlert
						title={paymentErrorTitle}
						show={paymentStatus === PAYMENT_STATUS.failed}
						error
						confirmBtnText="Retry Payment"
						confirmBtnCssClass="MuiButtonBase-root MuiButton-root ahia-login-button MuiButton-contained MuiButton-containedSecondary"
						onConfirm={() => this.purchaseMembership()}
					>
						{paymentErrorMessage}
					</SweetAlert>
					<SweetAlert
						title="Success"
						show={success}
						success
						confirmBtnText="Ok"
						confirmBtnCssClass="MuiButtonBase-root MuiButton-root ahia-login-button MuiButton-contained MuiButton-containedSecondary"
						onConfirm={() => {
							this.setState({ success: false, infoText: "" });
							this.props.history.push("/login");
						}}
					>
						{successText}
					</SweetAlert>
					<Container className="ahia-signup-container">
						<form
							className="ahia-login-form-container"
							onSubmit={(e) => this.handleOnSignupClick(e)}
						>
							<h2 style={{ color: "white" }}>
								<strong>Start using Ahia! by siging up.</strong>
							</h2>
							<input
								required
								id="fname"
								label="First Name"
								className="ahia-login-field"
								type="text"
								margin="normal"
								value={firstName}
								placeholder="First Name"
								onChange={(event) =>
									this.setState({ firstName: event.target.value })
								}
								autoComplete="given-name"
							/>
							<br />
							<br />
							<input
								required
								id="lname"
								label="Last Name"
								className="ahia-login-field"
								type="text"
								margin="normal"
								value={lastName}
								placeholder="Last Name"
								onChange={(event) =>
									this.setState({ lastName: event.target.value })
								}
								autoComplete="family-name"
							/>
							<br />
							<br />
							<input
								id="email"
								type="text"
								label="email"
								required
								className="ahia-login-field"
								value={email}
								onChange={(event) =>
									this.setState({ email: event.target.value })
								}
								margin="normal"
								placeholder="Email (used for payment)"
								autoComplete="email"
							/>
							<br />
							<br />
							<input
								required
								id="password"
								label="Password"
								className="ahia-login-field"
								type="password"
								margin="normal"
								value={password}
								placeholder="Password"
								minLength={6}
								onChange={(event) =>
									this.setState({ password: event.target.value })
								}
								autoComplete="new-password"
							/>
							<br />
							<br />
							<input
								required
								id="confirm-password"
								label="Confirm Password"
								className="ahia-login-field"
								type="password"
								margin="normal"
								value={confirmPassword}
								placeholder="Confirm Password"
								onChange={(event) =>
									this.setState({ confirmPassword: event.target.value })
								}
								autoComplete="new-password"
								minLength={6}
							/>
							<div className="d-flex" style={{ marginTop: 24 }}>
								<Button
									variant="contained"
									type="submit"
									color="primary"
									className="ahia-login-button"
								>
									<strong>Submit</strong>
								</Button>
								<Button
									variant="contained"
									type="button"
									color="primary"
									className="ahia-signup-button"
									onClick={() => this.handleOnLoginClick()}
								>
									<strong>Back to Login</strong>
								</Button>
							</div>
						</form>
					</Container>
				</div>
			</div>
		);
	}
}

export default withRouter(Signup);
