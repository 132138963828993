export const GROUP_PERMISSIONS = {
	superAdmin: {
		id: 1
	},
	user: {
		id: 2
	},
	client: {
		id: 3
	}
};
