import config from "../config/config";
import { loadStripe } from "@stripe/stripe-js";
import Axios from "axios";
import UserService from "./UserService";

const stripePromise = loadStripe(config.STRIPE_PK);

export default {
	async redirectToStripePayment() {
		const stripe = await stripePromise;
		const response = await Axios.post(
			`${config.PLATFORM_API}/payments/create_session`,
			{
				domainUrl: window.location.origin,
			}
		);
		const result = await stripe.redirectToCheckout({
			sessionId: response.data.id,
		});
		if (result.error) {
			throw result.error;
		}
	},

	async redirectToByPromotionPayment(email, type) {
		const stripe = await stripePromise;
		const response = await Axios.post(
			`${config.PLATFORM_API}/payments/buy_promotion`,
			{
				domainUrl: window.location.origin,
				email,
				type,
			},
			{
				headers: {
					authorization: UserService.getAuthToken(),
				},
			}
		);
		if (response.data.id.subscription_update) {
			return response.data.id;
		}
		const result = await stripe.redirectToCheckout({
			sessionId: response.data.id,
		});
		if (result.error) {
			throw result.error;
		}
	},
};
