import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import NotFound from "./components/NotFound/NotFound";
import Login from "./components/Login/Login";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import EnterToken from "./components/ResetPassword/EnterToken";
import Signup from "./components/Signup/Signup";
import ReactGA from "react-ga4";
import Main from "./components/Main/Main";
import config from "./config/config";

import "./App.css";
import "./assets/css/react-datetime.css";

ReactGA.initialize(config.GA_MEASUREMENT_ID);

function App() {
	return (
		<BrowserRouter>
			<Switch>
				<Route path="/error" component={NotFound} />
				<Route path="/login" component={Login} />
				<Route path="/enter-token" exact component={EnterToken} />
				<Route path="/signup" exact component={Signup} />
				<Route
					path="/admin"
					render={(props) => <ProtectedRoute component={Main} {...props} />}
				/>
				<Redirect to="/admin/dashboard" />
				{/* <ProtectedRoute path="/" component={Home} /> */}
			</Switch>
		</BrowserRouter>
	);
}

export default App;
