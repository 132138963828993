/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "./components/Dashboard/Dashboard";
import Promotions from "./components/Promotions/Promotions";
import Users from "./components/Users/Users";
import MapView from "./components/MapView/MapView";
import Campaigns from "./components/Campaigns/Campaigns";
import BuyPromotions from "./components/Promotions/Buy"
// import Notifications from "views/Notifications.jsx";
// import Icons from "views/Icons.jsx";
// import Typography from "views/Typography.jsx";
// import TableList from "views/Tables.jsx";
// import Maps from "views/Map.jsx";
// import UserPage from "views/User.jsx";
// import UpgradeToPro from "views/Upgrade.jsx";

var routes = [
	{
		path: "/dashboard",
		name: "Dashboard",
		icon: "nc-icon nc-bank",
		component: Dashboard,
		layout: "/admin"
	},
	{
		path: "/users",
		name: "Users",
		icon: "nc-icon nc-single-02",
		component: Users,
		layout: "/admin",
		admin: true
	},
	{
		path: "/promotions",
		name: "Promotions",
		icon: "nc-icon nc-diamond",
		component: Promotions,
		layout: "/admin"
	},
	{
		path: "/purchase",
		name: "Buy Promotions",
		icon: "nc-icon nc-money-coins",
		component: BuyPromotions,
		layout: "/admin"
	},
	{
		path: "/campaigns",
		name: "Campaigns",
		icon: "nc-icon nc-tile-56",
		component: Campaigns,
		layout: "/admin"
	},
	{
		path: "/maps",
		name: "Maps",
		icon: "nc-icon nc-pin-3",
		component: MapView,
		layout: "/admin"
	}
	// {
	// 	path: "/user-page",
	// 	name: "User Profile",
	// 	icon: "nc-icon nc-single-02",
	// 	component: UserPage,
	// 	layout: "/admin"
	// },
	// {
	// 	path: "/tables",
	// 	name: "Table List",
	// 	icon: "nc-icon nc-tile-56",
	// 	component: TableList,
	// 	layout: "/admin"
	// },
	// {
	// 	path: "/typography",
	// 	name: "Typography",
	// 	icon: "nc-icon nc-caps-small",
	// 	component: Typography,
	// 	layout: "/admin"
	// },
	// {
	// 	pro: true,
	// 	path: "/upgrade",
	// 	name: "Upgrade to PRO",
	// 	icon: "nc-icon nc-spaceship",
	// 	component: UpgradeToPro,
	// 	layout: "/admin"
	// }
];
export default routes;
